import { Table, Spinner, Badge, Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import CourseStgType from "../../components/CourseStgType";
import {
  createRemoteTeams,
  getRemoteActiveRequestedCourses,
  getRemoteDepartments,
  getRemoteFachbereiche,
  getRemoteOldCourseTemplatesForCurrentUser,
  getRemoteSemesters,
  getRemoteStgs,
  getRemoteTeachers,
  requestRemoteCourse,
  resolveTemplateSyncError,
} from "../../services/api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NonCurricularCourseForm from "../courses-manual/NonCurricularCourseForm";
import { Teacher } from "../../entities/teacher";
import { Semester } from "../../entities/semester";
import { Stg } from "../../entities/stg";
import { Department } from "../../entities/department";
import { Fachbereich } from "../../entities/fachbereich";
import dayjs from "dayjs";
import "./CoursesAdmin.css";
import { resolve } from "path";

export default function CoursesAdmin() {
  const { t, i18n } = useTranslation("common");
  const [courses, setCourses] = useState<any[]>([]);
  const [courseLoading, setCourseLoading] = useState<boolean>(true);
  const [showTeamsLoading, setShowTeamsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [courseRequestLoading, setCourseRequestLoading] =
    useState<boolean>(true);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [stgs, setStgs] = useState<Stg[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [fachbereiche, setFachbereiche] = useState<Fachbereich[]>([]);
  const [oldCourseTemplates, setOldCourseTemplates] = useState<Stg[]>([]);

  const [submitLoading, setSubmitLoading] = useState(false);

  const getTeachers = async () => {
    const data = await getRemoteTeachers();
    setTeachers(data);
  };
  const getSemesters = async () => {
    const data = await getRemoteSemesters();
    setSemesters(data);
  };
  const getStgs = async () => {
    const data = await getRemoteStgs();
    setStgs(data);
  };
  const getDepartments = async () => {
    const data = await getRemoteDepartments();
    setDepartments(data);
  };
  const getFachbereiche = async () => {
    const data = await getRemoteFachbereiche();
    setFachbereiche(data);
  };
  const getOldCourseTemplates = async () => {
    const data = await getRemoteOldCourseTemplatesForCurrentUser();
    setOldCourseTemplates(data);
  };

  const getAllRequestedCourses = async () => {
    setCourseLoading(true);
    const data = await getRemoteActiveRequestedCourses();
    setCourses(data);
    setCourseLoading(false);
  };

  const handleCreateTeam = async (course: any) => {
    const formData = new FormData();

    formData.append("courseTitle", course.titleMoodle);
    formData.append("shortSemester", course.wsSs + course.year.substring(2));
    if (course.fhsysCourseId) {
      formData.append("fhsysCourseId", course.fhsysCourseId);
    } else {
      formData.append("requestedId", course.id);
    }

    formData.append("teachers", "[]");
    setShowTeamsLoading(true);
    await createRemoteTeams(formData);
    setShowTeamsLoading(false);
    setCourses([]);
    getAllRequestedCourses();
  };

  const requestCourse = async (course: any) => {
    const formData = new FormData();
    Object.keys(course).forEach((key: string) => {
      const param = course[key as keyof any];
      if (param) {
        formData.append(key, param.toString());
      } else {
        formData.append(key, "");
      }
    });
    await requestRemoteCourse(formData);
  };

  const handleTemplateSyncErrorResolved = async (course: any) => {
    const formData = new FormData();
    formData.append("courseId", course.id);
    formData.append("fhsysCourseId", course.fhsysCourseId);
    await resolveTemplateSyncError(formData);
    getAllRequestedCourses();
  };

  const handleSubmit = async (newCourse: any) => {
    setSubmitLoading(true);
    await requestCourse(newCourse);
    setCourses([]);
    setSubmitLoading(false);
    getAllRequestedCourses();
    handleClose();
  };

  useEffect(() => {
    getTeachers();
    getSemesters();
    getStgs();
    getDepartments();
    getFachbereiche();
    getOldCourseTemplates();
    getAllRequestedCourses();
  }, []);

  const getTranslatedInternalStatus = (internalStatus: string) => {
    if (internalStatus == 'waiting') {
      return <Badge bg="warning">{t("courseForm.InternalStatus.waiting")}</Badge>;
    } else if (internalStatus == 'completed') {
      return <Badge bg="success">{t("courseForm.InternalStatus.completed")}</Badge>;
    }
    return <Badge bg="info">{t("courseForm.InternalStatus.open")}</Badge>;
  }

  const listItems = courses.map((course) => (
    <tr className={"priority-" + course.priority} key={course.id}>
      <td>{getTranslatedInternalStatus(course.internalStatus)}</td>
      <td>
        {" "}
        {course.curricular ? (
          <Badge className="avatarlike" pill bg="info">
            C
          </Badge>
        ) : (
          <Badge className="avatarlike" pill bg="dark">
            M
          </Badge>
        )}
      </td>
      <td>{dayjs(new Date(course.createdAt)).format(t("dateFormat"))}</td>
      <td>{JSON.parse(course.createdFrom).name}</td>
      <td>
        {JSON.parse(course.teachers).map((teacher: any) => {
          return <div key={course.id + "_" + teacher.id}>{teacher.name}</div>;
        })}
      </td>
      <td>{course.curricular ? course.fhsysCourseId : course.lvaNumber}</td>
      <td>
        <Link to={`/courses-admin/${course.id}`}>{course.titleMoodle}</Link>
      </td>
      <td>{course.stg}</td>
      <td>
        {course.wsSs} {course.year}
      </td>
      <td>
        {course.moodleCourseStatus == "requested" && (
          <Badge bg="warning">{t("moodleCourseStatus.inProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "accepted" && (
          <Badge bg="success">{t("moodleCourseStatus.created")}</Badge>
        )}
        {course.moodleCourseStatus == "rejected" && (
          <Badge bg="dark">{t("moodleCourseStatus.rejected")}</Badge>
        )}
        {course.moodleCourseStatus == "waitingForTemplateSync" && (
          <Badge bg="info">{t("moodleCourseStatus.importInProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "templateSyncError" && (
          <>
            <Badge bg="danger">{t("moodleCourseStatus.importError")}</Badge>
            <div className="mt-2">
              <a
                href="#"
                onClick={(event: any) => {
                  event.preventDefault();
                  handleTemplateSyncErrorResolved(course);
                }}
              >
                {t("errorResolved")}
              </a>
            </div>
          </>
        )}
      </td>
      <td>
        {course.teamsCourseStatus == "requested" && (
          <Badge bg="warning">{t("teamsCourseStatus.inProgress")}</Badge>
        )}
        {course.teamsCourseStatus == "accepted" && (
          <Badge bg="success">{t("teamsCourseStatus.created")}</Badge>
        )}
        {course.moodleCourseStatus == "accepted" &&
          course.teamsCourseStatus != "accepted" &&
          course.teamsCourseStatus != "requested" && (
            <a
              href="#"
              className="link-primary"
              onClick={() => {
                handleCreateTeam(course);
              }}
            >
              {t("courseList.requestTeamsCourse")}
            </a>
          )}
      </td>
    </tr>
  ));

  return (
    <div>
      {showTeamsLoading && (
        <div
          className="fullMask"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "black",
            opacity: "0.7",
            zIndex: "1",
          }}
        ></div>
      )}
      <h2>{t("menu.moodleAdminTitle")}</h2>
      <Table className="table">
        <thead>
          <tr>
            <th scope="col">{t("courseForm.internalStatusLabel")}</th>
            <th scope="col"></th>
            <th scope="col">{t("courses.creationHeader")}</th>
            <th scope="col">{t("courses.createrHeader")}</th>
            <th scope="col">{t("courses.teacherHeader")}</th>
            <th scope="col">{t("courseForm.shortNameLabel")}</th>
            <th scope="col">{t("courses.titleHeader")}</th>
            <th scope="col">{t("courses.stgHeader")}</th>
            <th scope="col">{t("courses.semesterHeader")}</th>
            <th scope="col">{t("courses.moodleHeader")}</th>
            <th scope="col">{t("courses.teamsHeader")}</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </Table>
      {courses.length <= 0 && courseLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {courses.length <= 0 && !courseLoading && (
        <div>{t("courses.noCoursesFound")}</div>
      )}
      <Button variant="dark" size="sm" onClick={handleShow}>
        {t("courses.createManualCourseButton")}
      </Button>
      <NonCurricularCourseForm
        show={showModal}
        handleClose={handleClose}
        submitLoading={submitLoading}
        handleSubmit={handleSubmit}
        teachers={teachers}
        semesters={semesters}
        stgs={stgs}
        departments={departments}
        fachbereiche={fachbereiche}
        oldCourseTemplates={oldCourseTemplates}
        setDefaultUser={false}
      />
      <Modal
        show={showTeamsLoading}
        onHide={() => {
          setShowTeamsLoading(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Teams</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{t("courses.teamsCourseCreationInProgress")}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
