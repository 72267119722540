import { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  createRemoteManualMoodleCourse,
  getRemoteCategories,
  getRemoteManualCourseByCourseId,
  getRemoteOldCourseTemplatesForCurrentUser,
  getRemoteSemesters,
  getRemoteTeachers,
  rejectRemoteRequestedCourse,
} from "../../services/api";
import { Formik } from "formik";
import * as yup from "yup";
import { Semester } from "../../entities/semester";
import { Teacher } from "../../entities/teacher";
import { Typeahead } from "react-bootstrap-typeahead";
import MoodleCategoryChanger from "./MoodleCategoryChanger";
import { useTranslation } from "react-i18next";
import { useErrorStore } from "../../errorStore";
import { CourseTemplate } from "../../entities/courseTemplate";

export default function CoursesAdminDetail() {
  const { t, i18n } = useTranslation("common");
  const { courseId } = useParams();
  const navigate = useNavigate();

  const [showCategoryChangeModal, setShowCategoryChangeModal] =
    useState<boolean>(false);
  const emptyMoodleCategory = {
    key: "",
    label: "",
    fullPathName: "",
  };
  const [selectedMoodleCategory, setSelectedMoodleCategory] =
    useState<any>(emptyMoodleCategory);

  const [course, setCourse] = useState<any>(null);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedTeachers, setSelectedTeachers] = useState<any[]>([]);
  const [selectedCoTeachers, setSelectedCoTeachers] = useState<any[]>([]);
  const [selectedNonEditingTeachers, setSelectedNonEditingTeachers] = useState<
    any[]
  >([]);
  const [selectedOldCourseTemplate, setSelectedOldCourseTemplate] = useState<
    any[]
  >([]);
  const [oldCourseTemplates, setOldCourseTemplates] = useState<
    CourseTemplate[]
  >([]);

  const setErrorMessage = useErrorStore((state) => state.setErrorMessage);

  const semesterOptions = semesters.map((semester: Semester) => (
    <option
      key={semester.wsSs + semester.year}
      value={semester.wsSs + semester.year}
    >
      {semester.wsSs} {semester.year}
    </option>
  ));
  const teachersInputValues = teachers.map((teacher: Teacher) => {
    return {
      id: teacher.username,
      name: teacher.firstname + " " + teacher.lastname,
    };
  });

  const getCourse = async () => {
    const data = await getRemoteManualCourseByCourseId(courseId);
    setCourse(data);
    setSelectedTeachers(JSON.parse(data.teachers));
    setSelectedCoTeachers(JSON.parse(data.coTeachers));
    setSelectedNonEditingTeachers(JSON.parse(data.nonEditingTeachers));
    handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
      JSON.parse(data.teachers),
      JSON.parse(data.coTeachers)
    );
    if (data.moodleCategory) {
      setSelectedMoodleCategory(JSON.parse(data.moodleCategory));
    }
  };
  const getSemesters = async () => {
    const data = await getRemoteSemesters();
    setSemesters(data);
  };
  const getTeachers = async () => {
    const data = await getRemoteTeachers();
    setTeachers(data);
  };
  const getCategories = async () => {
    const data = await getRemoteCategories();
    setCategories(data);
  };
  const getOldCourseTemplates = async () => {
    const teachers = [];
    for (const teacher of selectedTeachers) {
      teachers.push(teacher.id);
    }
    for (const teacher of selectedCoTeachers) {
      teachers.push(teacher.id);
    }
    const data = await getRemoteOldCourseTemplatesForCurrentUser(teachers);
    setOldCourseTemplates(data);
  };
  const createManualMoodleCourse = async (course: any) => {
    const formData = new FormData();
    Object.keys(course).forEach((key: string) => {
      const param = course[key as keyof any];
      if (param) {
        formData.append(key, param.toString());
      } else {
        formData.append(key, "");
      }
    });
    return await createRemoteManualMoodleCourse(formData);
  };
  const handleSubmit = async (values: any) => {
    setSubmitLoading(true);
    const ret = await createManualMoodleCourse(values);
    setSubmitLoading(false);
    if (ret) {
      if (ret.status == 400 && ret.detail == "shortnametaken") {
        setErrorMessage(t("errorMessages.shortnametaken"));
      } else {
        setErrorMessage(t("errorMessages.unknownError"));
      }
    } else {
      window.location.href = "/courses-admin";
    }
  };
  const handleCategoryChangeModalClose = () => {
    setShowCategoryChangeModal(false);
  };
  const handleCategoryChangeModalShow = () => {
    setShowCategoryChangeModal(true);
  };
  const handleInitialGetRemoteOldCourseTemplatesForCurrentUser = async (
    courseTeachers: any,
    courseCoTeachers: any
  ) => {
    const teachers = [];
    for (const teacher of courseTeachers) {
      teachers.push(teacher.id);
    }
    for (const teacher of courseCoTeachers) {
      teachers.push(teacher.id);
    }
    const data = await getRemoteOldCourseTemplatesForCurrentUser(teachers);
    setOldCourseTemplates(data);
  };

  const handleRejectMoodleCourse = async (course: any) => {
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append("courseId", course.id);
    await rejectRemoteRequestedCourse(formData);
    setSubmitLoading(false);
    window.location.href = "/courses-admin";
  };

  useEffect(() => {
    getCourse();
    getSemesters();
    getCategories();
    getTeachers();
  }, []);

  const oldCourseTemplateInputValues = oldCourseTemplates.map(
    (courseTemplate: CourseTemplate) => {
      return {
        id: courseTemplate.courseId,
        name: courseTemplate.courseTitle,
        courseShortTitle: courseTemplate.courseShortTitle,
      };
    }
  );

  const header = (
    <div className="mb-4">
      <a
        onClick={() => {
          navigate(-1);
        }}
        href="#"
      >
        <FiArrowLeft /> {t("courses.backToCourseListButton")}
      </a>
    </div>
  );

  if (!course) {
    return (
      <>
        {header}
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </>
    );
  }

  const schema = yup.object().shape({
    lvaNumber: yup.string().required(t("courseForm.lvaNumberRequiredMessage")),
    titleMoodle: yup
      .string()
      .required(t("courseForm.lvaNumberRequiredMessage")),
    teachers: yup
      .string()
      .required(t("courseForm.courseInstructorRequiredMessage"))
      .notOneOf(["[]"], t("courseForm.courseInstructorRequiredMessage")),
  });

  const shortname = course.curricular ? course.fhsysCourseId : course.lvaNumber;

  return (
    <>
      {header}
      <h4 className="mb-3">{t("courseForm.courseDetails")}</h4>
      <Container className="mb-5">
        <Formik
          validationSchema={schema}
          onSubmit={(values, helpers) => {
            values.teachers = JSON.stringify(selectedTeachers);
            values.coTeachers = JSON.stringify(selectedCoTeachers);
            values.nonEditingTeachers = JSON.stringify(
              selectedNonEditingTeachers
            );
            values.moodleCategory = JSON.stringify(selectedMoodleCategory);
            if (selectedOldCourseTemplate.length > 0) {
              values.templateOldCourseId = selectedOldCourseTemplate[0].id;
            }
            if (values.moodleCategory == JSON.stringify(emptyMoodleCategory)) {
              helpers.setFieldError(
                "moodleCategory",
                t("courseForm.moodleCategoryRequiredMessage")
              );
            } else {
              handleSubmit(values);
            }
          }}
          initialValues={{
            id: course.id,
            teacherUsername: course.teacherUsername,
            lvaNumber: shortname,
            titleMoodle: course.titleMoodle,
            department: course.department,
            stg: course.stg,
            fachbereich: course.fachbereich,
            stgType: course.stgType,
            semester: course.wsSs + course.year,
            teachers: JSON.stringify(course.teachers),
            descriptionMoodle: course.descriptionMoodle,
            coTeachers: "",
            nonEditingTeachers: "",
            teacherComment: course.teacherComment,
            moodleCourseStatus: course.moodleCourseStatus,
            moodleCategory: selectedMoodleCategory.fullPath,
            curricular: course.curricular,
            fhsysCourseId: course.fhsysCourseId,
            templateOldCourseId: course.templateOldCourseId,
            createdAt: course.createdAt,
            createdFrom: course.createdFrom,
            studentSync: course.studentSync,
            moodleCourseId: course.moodleCourseId,
            save: false,
            internalStatus: course.internalStatus
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
            validateForm
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="courseForm.lvNumber">
                <Form.Label>{t("courseForm.shortNameLabel")}</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  name="lvaNumber"
                  value={values.lvaNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.lvaNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lvaNumber?.toString()}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="courseForm.title">
                <Form.Label>{t("courseForm.titleLabel")}</Form.Label>
                <Form.Control
                  type="text"
                  name="titleMoodle"
                  value={values.titleMoodle}
                  onChange={handleChange}
                  isInvalid={!!errors.titleMoodle}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.titleMoodle?.toString()}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="courseForm.department">
                <Form.Label>{t("courseForm.departmentLabel")}</Form.Label>
                <Form.Control
                  type="text"
                  name="department"
                  value={values.department}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="courseForm.stg">
                <Form.Label>{t("courseForm.stgLabel")}</Form.Label>
                <Form.Control
                  type="text"
                  name="stg"
                  value={values.stg}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="courseForm.fachbereich">
                <Form.Label>{t("courseForm.fachbereichLabel")}</Form.Label>
                <Form.Control
                  type="text"
                  name="fachbereich"
                  value={values.fachbereich}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="courseForm.moodleCategory"
              >
                <Form.Label>{t("courseForm.moodleCategoryLabel")}</Form.Label>
                <div>
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      handleCategoryChangeModalShow();
                    }}
                  >
                    {t("courseForm.chooseMoodleCategory")}
                  </a>
                </div>
                <Form.Control
                  type="text"
                  name="moodleCategory"
                  value={selectedMoodleCategory.fullPathName}
                  onChange={handleChange}
                  disabled
                  isInvalid={!!errors.moodleCategory}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.moodleCategory?.toString()}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="courseForm.semester">
                <Form.Label>{t("courseForm.semesterLabel")}</Form.Label>
                <Form.Select
                  aria-label="Semester wählen"
                  name="semester"
                  value={values.semester}
                  onChange={handleChange}
                >
                  <option>{t("courseForm.chooseSemester")}</option>
                  <option key="no-semester" value="no-semester">
                    {t("courseForm.noSemester")}
                  </option>
                  {semesterOptions}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="courseForm.teacher">
                <Form.Label>
                  {t("courseForm.fhsysCourseInstructorLabel")}
                </Form.Label>
                <Typeahead
                  id="courseForm.teacher"
                  labelKey="name"
                  multiple
                  onChange={(selected) => {
                    setSelectedTeachers(selected);
                    setFieldValue("teachers", JSON.stringify(selected));
                    handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
                      selected,
                      selectedCoTeachers
                    );
                  }}
                  options={teachersInputValues}
                  placeholder={t("courseForm.pleaseChoose")}
                  selected={selectedTeachers}
                  isInvalid={!!errors.teachers}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.teachers?.toString()}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("courseForm.teacherLabel")}</Form.Label>
                <Typeahead
                  id="courseForm.coTeacher"
                  labelKey="name"
                  multiple
                  onChange={(selected) => {
                    setSelectedCoTeachers(selected);
                    handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
                      selectedTeachers,
                      selected
                    );
                  }}
                  options={teachersInputValues}
                  placeholder={t("courseForm.pleaseChoose")}
                  selected={selectedCoTeachers}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("courseForm.nonEditingTeachers")}</Form.Label>
                <Typeahead
                  id="courseForm.nonEditingTeacher"
                  labelKey="name"
                  multiple
                  onChange={setSelectedNonEditingTeachers}
                  options={teachersInputValues}
                  placeholder={t("courseForm.pleaseChoose")}
                  selected={selectedNonEditingTeachers}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="courseForm.description">
                <Form.Label>{t("courseForm.courseDescription")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="descriptionMoodle"
                  value={values.descriptionMoodle}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("courseForm.courseTemplateLabel")}</Form.Label>
                {
                  <Typeahead
                    id="courseForm.courseTemplate"
                    labelKey="name"
                    onChange={setSelectedOldCourseTemplate}
                    options={oldCourseTemplateInputValues}
                    placeholder="Bitte Vorlage wählen..."
                    selected={selectedOldCourseTemplate}
                  />
                }
                <div>
                  {course.templateOldCourseId && (
                    <>
                      {t("courseForm.chosenTemplate")}{" "}
                      {course.templateOldCourseId}
                    </>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="courseForm.teacherComment"
              >
                <div className="mb-1">
                  {values.curricular && (
                    <strong>
                      {t("courseForm.studentSyncDeactivatedInfo")}
                    </strong>
                  )}
                </div>
                <Form.Label>{t("courseForm.reasonLabel")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="teacherComment"
                  value={values.teacherComment}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="courseForm.internalStatus">
                <Form.Label>{t("courseForm.internalStatusLabel")}</Form.Label>
                <Form.Select
                  aria-label="Interner Status"
                  name="internalStatus"
                  value={values.internalStatus}
                  onChange={handleChange}
                >
                  <option key="" value="">{t("courseForm.InternalStatus.open")}</option>
                  <option key="waiting" value="waiting">{t("courseForm.InternalStatus.waiting")}</option>
                  <option key="completed" value="completed">{t("courseForm.InternalStatus.completed")}</option>
                </Form.Select>
              </Form.Group>
              {values.curricular && (
                <Form.Group
                  className="mb-3"
                  controlId="courseForm.studentsSync"
                >
                  <Form.Check
                    type="switch"
                    label={t("courseForm.studentsSyncLabel")}
                    onChange={(e) => {
                      setFieldValue("studentSync", e.target.checked);
                    }}
                    checked={values.studentSync}
                  />
                </Form.Group>
              )}
              <div className="d-flex justify-content-end">
                {course.moodleCourseStatus == "requested" ? (
                  <>
                    <Button
                      variant="outline-primary"
                      disabled={submitLoading}
                      className="ms-2"
                      onClick={
                        () => {
                          values.save = true;
                          handleSubmit();
                        }
                      }
                    >
                      {submitLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {t("courseForm.saveMoodleCourse")}
                    </Button>
                    <Button
                      variant="outline-danger"
                      disabled={submitLoading}
                      className="ms-2"
                      onClick={() => {
                        handleRejectMoodleCourse(course);
                      }}
                    >
                      {submitLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {t("courseList.rejectMoodleCourse")}
                    </Button>

                    <Button
                      variant="primary"
                      disabled={submitLoading}
                      className="ms-2"
                      onClick={
                        () => {
                          values.save = false;
                          handleSubmit();
                        }
                      }
                    >
                      {submitLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {t("courseList.createMoodleCourse")}
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outline-primary"
                    type="submit"
                    disabled={submitLoading}
                    className="ms-2"
                  >
                    {submitLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {t("courseForm.saveMoodleCourse")}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
      <MoodleCategoryChanger
        show={showCategoryChangeModal}
        handleClose={handleCategoryChangeModalClose}
        setSelectedMoodleCategory={setSelectedMoodleCategory}
        categories={categories}
        department={course.department}
        fachbereich={course.fachbereich}
        semester={`${course.wsSs} ${course.year}`}
      />
    </>
  );
}
