import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  getRemoteGlobalInfoText,
  setRemoteGlobalInfoText,
} from "../services/api";

export default function Settings() {
  const { t, i18n } = useTranslation("common");
  const [globalInfoTextGerman, setGlobalInfoTextGerman] = useState("");
  const [globalInfoTextEnglish, setGlobalInfoTextEnglish] = useState("");
  const [loading, setLoading] = useState(false);

  const getGlobalInfoText = async () => {
    setLoading(true);
    const data = await getRemoteGlobalInfoText();
    if (data) {
      setGlobalInfoTextGerman(JSON.parse(data).de);
      setGlobalInfoTextEnglish(JSON.parse(data).en);
    }
    setLoading(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      de: globalInfoTextGerman,
      en: globalInfoTextEnglish,
    };
    const formData = new FormData();
    formData.append("globalInfoText", JSON.stringify(data));
    await setRemoteGlobalInfoText(formData);
    getGlobalInfoText();
  };

  useEffect(() => {
    getGlobalInfoText();
  }, []);

  return (
    <div style={{ maxWidth: "800px" }}>
      <h3>Info Box</h3>
      {loading && <Spinner animation="border" />}
      {!loading && (
        <>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3"
              controlId="settingsForm.globalInfoTextGerman"
            >
              <Form.Label>{t("settingsForm.globalInfoTextGerman")}</Form.Label>
              <Form.Control
                type="text"
                name="globalInfoTextGerman"
                value={globalInfoTextGerman}
                onChange={(e) => setGlobalInfoTextGerman(e.target.value)}
              />
              <Form.Label>{t("settingsForm.globalInfoTextEnglish")}</Form.Label>
              <Form.Control
                type="text"
                name="globalInfoTextEnglish"
                value={globalInfoTextEnglish}
                onChange={(e) => setGlobalInfoTextEnglish(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {t("save")}
            </Button>
          </Form>
        </>
      )}
    </div>
  );
}
