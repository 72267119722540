import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";

function GlobalInfoBox(
  { globalInfoText }: { globalInfoText: string } = { globalInfoText: "" }
) {
  const { t, i18n } = useTranslation("common");
  const currentText = JSON.parse(globalInfoText)
    ? JSON.parse(globalInfoText)[i18n.language]
    : "";
  return (
    <>
      {currentText && (
        <Alert variant="warning">
          <p style={{ fontWeight: "bold" }}>{t("alert.notice")}</p>
          <p>{currentText}</p>
        </Alert>
      )}
    </>
  );
}

export default GlobalInfoBox;
