import { Link } from "react-router-dom";
import {
  FiFileText,
  FiHelpCircle,
  FiInbox,
  FiLayers,
  FiLogOut,
  FiRefreshCw,
  FiSettings,
  FiCheckSquare,
} from "react-icons/fi";

import iconMoodle from "./icon-moodle.svg";
import iconTeams from "./icon-teams.svg";
import "./Menu.css";
import { useContext } from "react";
import { UserContext } from "../userContext";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const user = useContext(UserContext);
  const { t, i18n } = useTranslation("common");

  if (!user) return <></>;

  return (
    <div className="position-sticky pt-3">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link to="/my-courses" className="nav-link">
            <FiRefreshCw />
            {t("menu.curriclarTitle")}
          </Link>
          <Link to="/courses-manual" className="nav-link">
            <FiInbox />
            {t("menu.manualTitle")}
          </Link>
        </li>
        {user.role == "admin" && (
          <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>{t("menu.adminHeadline")}</span>
            </h6>
            <li className="nav-item">
              <Link to="/courses-admin" className="nav-link">
                <FiLayers /> {t("menu.newMoodleCoursesTitle")}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/completed-courses-admin" className="nav-link">
                <FiCheckSquare /> {t("menu.completedMoodleCoursesTitle")}
              </Link>
            </li>
          </>
        )}
        <hr />
        <li className="nav-item">
          <Link to="/info" className="nav-link">
            <FiHelpCircle /> {t("menu.infoTitle")}
          </Link>
        </li>
      </ul>
      {user.role == "admin" && (
        <>
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>{t("menu.settingsHeadline")}</span>
          </h6>
          <ul className="nav flex-column mb-2">
            <li className="nav-item">
              <Link to="/settings" className="nav-link">
                <FiSettings /> {t("menu.settingsGeneralTitle")}
              </Link>
            </li>
          </ul>
        </>
      )}
    </div>
  );
}
