import { t } from "i18next";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function CourseFilter(props: any) {
  const { t, i18n } = useTranslation("common");
  const { showInstructor, filterValues, setFilterValues, teachers } = props;

  const setField = (field: string, value: string) => {
    setFilterValues({
      ...filterValues,
      [field]: value,
    });
  };

  const myTeachers: any = [];
  Object.keys(teachers).forEach(function (key, index) {
    myTeachers.push(teachers[key]);
  });
  myTeachers.sort((t1:any, t2:any) => {
    const splittedT1 = t1.name.split(" ");
    const splittedT2 = t2.name.split(" ");
    const lastName1 = splittedT1[splittedT1.length - 1];
    const lastName2 = splittedT2[splittedT2.length - 1];
    return lastName1 > lastName2 ? 1 : lastName1 < lastName2 ? -1 : 0;
  });
  const teacherOptions = myTeachers.map((myTeacher: any) => {
    return <option value={myTeacher.id}>{myTeacher.name}</option>;
  });
  return (
    <div style={{ maxWidth: "600px" }}>
      <Row className="mb-2">
        <Form.Group as={Col}>
          <Form.Label>{t('search.globalLabel')}</Form.Label>
          <Form.Control
            type="text"
            value={filterValues.title}
            name="title"
            placeholder={t('search.inputPlaceholder')}
            onChange={(e) => setField("title", e.target.value)}
          />
        </Form.Group>
        {!!showInstructor && (
          <Form.Group as={Col}>
            <Form.Label>{t('courseForm.teacherLabel')}</Form.Label>
            <Form.Select
              aria-label="Teacher Filter"
              value={filterValues.teacher}
              onChange={(a) => setField("teacher", a.target.value)}
            >
              <option value="">{t('search.showAllLabel')}</option>
              {teacherOptions}
            </Form.Select>
          </Form.Group>
        )}
      </Row>
    </div>
  );
}
