import { useState } from "react";
import RequestTeamForm from "./RequestTeamForm";
import { Accordion, Button, Container } from "react-bootstrap";

export default function Teams() {
  const [showInfo, setShowInfo] = useState(true);

  if (showInfo) {
    return (
      <Container style={{ maxWidth: "800px" }}>
        <h2>Microsoft Teams Bestellformular</h2>
        <p>
          Benötigen Sie ein Team oder wäre ein Kanal innerhalb eines
          existierenden Teams oder eine Chat-Gruppe ausreichend?
        </p>
        <p>
          Einen Kanal kann der Eigentümer des Teams für Sie anlegen. Eine
          Chat-Gruppe können sie jederzeit selbst anlegen.{" "}
        </p>
        <p>
          Sie sind sich nicht sicher? Hier finden Sie Informationen über Teams,
          Kanäle und Chat-Gruppen:
        </p>
        <div className="mb-3">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Wann Sie Teams, Kanäle oder Gruppen-Chats nutzen sollten
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  In Microsoft Teams gibt es verschiedene Möglichkeiten, wie Sie
                  mit anderen kommunizieren und zusammenarbeiten können. Hier
                  ist eine kurze Übersicht, wann Sie was nutzen sollten:
                </p>
                <h4>Teams</h4>
                <p>
                  <strong>Wann nutzen?</strong> Wenn Sie eine Gruppe von
                  Personen haben, die regelmäßig zusammenarbeiten müssen, z.B.
                  für ein Projekt oder eine Abteilung.
                </p>
                <p>
                  <strong>Was ist das?</strong> Ein Team ist ein gemeinsam
                  genutzter Bereich, in dem Mitglieder Dateien, Tools und
                  Unterhaltungen teilen können.
                </p>
                <h4>Kanäle</h4>
                <p>
                  <strong>Wann nutzen?</strong> Wenn Sie innerhalb eines Teams
                  spezifische Themen oder Projekte organisieren möchten.
                </p>
                <p>
                  <strong>Was ist das?</strong> Ein Kanal ist eine Untereinheit
                  eines Teams, ähnlich wie verschiedene Fernsehkanäle. Jeder
                  Kanal kann eigene Unterhaltungen und Dateien haben.
                </p>
                <h4>Gruppen-Chat</h4>
                <p>
                  <strong>Wann nutzen?</strong> Für schnelle, informelle
                  Kommunikation mit einer oder mehreren Personen, die nicht
                  unbedingt Teil eines Teams sein müssen.
                </p>
                <p>
                  <strong>Was ist das?</strong> Ein Gruppen-Chat ist ein Chat
                  außerhalb der Struktur von Teams und Kanälen, ideal für
                  kurzfristige oder ad-hoc Gespräche. Die Chat-Gruppen können
                  auch über längere Zeiträume genutzt werden.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <Button onClick={() => setShowInfo(false)}>
          Ich benötige ein Team
        </Button>{" "}
        {/* <Button variant="secondary" onClick={() => window.close()}>
          Eine Chat-Gruppe oder ein Kanal genügt
        </Button> */}
      </Container>
    );
  }

  return (
    <div>
      <RequestTeamForm />
    </div>
  );
}
