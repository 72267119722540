import { Formik } from "formik";
import { Button, Form, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Teacher } from "../entities/teacher";
import { useTranslation } from "react-i18next";

export default function UserChangeForm(props: any) {
  const {t, i18n} = useTranslation('common');

  const {
    handleSubmit,
    selectedTeacher,
    setSelectedTeacher,
    teachers,
    submitLoading,
  } = props;

  const teachersInputValues = teachers.map((teacher: Teacher) => {
    return {
      id: teacher.username,
      name:
        teacher.firstname +
        " " +
        teacher.lastname +
        " (" +
        teacher.username +
        ")",
    };
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="courseForm.teacher">
        <Form.Label>Teacher</Form.Label>
        <Typeahead
          id="courseForm.teacher"
          labelKey="name"
          onChange={setSelectedTeacher}
          options={teachersInputValues}
          placeholder="Bitte wählen..."
          selected={selectedTeacher}
        />
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        disabled={submitLoading}
        className="ms-2"
      >
        {submitLoading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {t('changeUser')}
      </Button>
    </Form>
  );
}
