import {
  Table,
  Spinner,
  Button,
  Badge,
  Modal,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import CourseStgType from "../../components/CourseStgType";
import NonCurricularCourseForm from "./NonCurricularCourseForm";
import { Teacher } from "../../entities/teacher";
import { Semester } from "../../entities/semester";
import { Stg } from "../../entities/stg";
import {
  getRemoteManualCoursesForCurrentUser,
  getRemoteDepartments,
  getRemoteFachbereiche,
  getRemoteSemesters,
  getRemoteStgs,
  getRemoteTeachers,
  requestRemoteCourse,
  createRemoteTeams,
} from "../../services/api";
import { Department } from "../../entities/department";
import { Fachbereich } from "../../entities/fachbereich";
import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";
import { useLocation, useParams } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CoursesManual() {
  const { t, i18n } = useTranslation("common");
  let query = useQuery();

  const [showModal, setShowModal] = useState(false);
  const [showTeamsLoading, setShowTeamsLoading] = useState<boolean>(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [courses, setCourses] = useState<any[]>([]);
  const [courseRequestLoading, setCourseRequestLoading] =
    useState<boolean>(true);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [stgs, setStgs] = useState<Stg[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [fachbereiche, setFachbereiche] = useState<Fachbereich[]>([]);
  const [useFhsysCourseId, setUseFhsysCourseId] = useState<boolean>(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const getTeachers = async () => {
    const data = await getRemoteTeachers();
    setTeachers(data);
  };
  const getSemesters = async () => {
    const data = await getRemoteSemesters();
    setSemesters(data);
  };
  const getStgs = async () => {
    const data = await getRemoteStgs();
    setStgs(data);
  };
  const getDepartments = async () => {
    const data = await getRemoteDepartments();
    setDepartments(data);
  };
  const getFachbereiche = async () => {
    const data = await getRemoteFachbereiche();
    setFachbereiche(data);
  };
  const getAllManualCourses = async () => {
    setCourseRequestLoading(true);
    const data = await getRemoteManualCoursesForCurrentUser();
    setCourses(data);
    setCourseRequestLoading(false);
  };

  const requestCourse = async (course: any) => {
    const formData = new FormData();
    Object.keys(course).forEach((key: string) => {
      const param = course[key as keyof any];
      if (param) {
        formData.append(key, param.toString());
      } else {
        formData.append(key, "");
      }
    });
    await requestRemoteCourse(formData);
  };

  const handleSubmit = async (newCourse: any) => {
    setSubmitLoading(true);
    await requestCourse(newCourse);
    setCourses([]);
    setSubmitLoading(false);
    getAllManualCourses();
    handleClose();
  };
  const handleCreateTeam = async (course: any) => {
    const formData = new FormData();
    formData.append("courseTitle", course.titleMoodle);
    formData.append("shortSemester", course.wsSs + course.year.substring(2));
    if (course.fhsysCourseId) {
      formData.append("fhsysCourseId", course.fhsysCourseId);
    } else {
      formData.append("requestedId", course.id);
    }

    formData.append("teachers", "[]");
    setShowTeamsLoading(true);
    await createRemoteTeams(formData);
    setShowTeamsLoading(false);
    setCourses([]);
    getAllManualCourses();
  };

  useEffect(() => {
    if (query.get('fhsysCourseId')) {
      setUseFhsysCourseId(true);
      handleShow();
    }
    getTeachers();
    getSemesters();
    getStgs();
    getDepartments();
    getFachbereiche();
    getAllManualCourses();
  }, []);

  const listItems = courses.map((course) => (
    <tr key={course.id}>
      <td>{course.lvaNumber}</td>
      <td>{course.titleMoodle}</td>
      <td>{course.stg}</td>
      <td>
        {course.wsSs} {course.year}
      </td>
      <td>
        {JSON.parse(course.teachers).map((teacher: any) => {
          return <div key={course.id + "_" + teacher.id}>{teacher.name}</div>;
        })}
      </td>
      <td>
        {course.moodleCourseStatus == "requested" && (
          <Badge bg="warning">{t("moodleCourseStatus.inProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "accepted" && (
          <Badge bg="success">{t("moodleCourseStatus.created")}</Badge>
        )}
        {course.moodleCourseStatus == "rejected" && (
          <Badge bg="dark">{t("moodleCourseStatus.rejected")}</Badge>
        )}
        {course.moodleCourseStatus == "waitingForTemplateSync" && (
          <Badge bg="info">{t("moodleCourseStatus.importInProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "templateSyncError" && (
          <Badge bg="danger">{t('moodleCourseStatus.importError')}</Badge>
        )}
      </td>
      <td>
        {course.teamsCourseStatus == "requested" && (
          <Badge bg="warning">{t("teamsCourseStatus.inProgress")}</Badge>
        )}
        {course.teamsCourseStatus == "accepted" && (
          <Badge bg="success">{t("teamsCourseStatus.created")}</Badge>
        )}
        {course.moodleCourseStatus == "accepted" &&
          course.teamsCourseStatus != "accepted" &&
          course.teamsCourseStatus != "requested" && (
            <a
              href="#"
              className="link-primary"
              onClick={() => {
                handleCreateTeam(course);
              }}
            >
              {t("courseList.requestTeamsCourse")}
            </a>
          )}
      </td>
    </tr>
  ));

  const statusInfoBox = (
    <Popover id="popover-basic">
      <Popover.Header className="d-flex justify-content-between align-items-center">
        <div className="h5" style={{ marginTop: "0.1rem", marginBottom: "0" }}>
          Info
        </div>
      </Popover.Header>
      <Popover.Body>
        <div className="mb-2"><Badge bg="warning">{t("moodleCourseStatus.inProgress")}</Badge> <br/>{t("moodleCourseStatus.inProgressInfo")}</div>
        <div className="mb-2"><Badge bg="info">{t("moodleCourseStatus.importInProgress")}</Badge> <br/>{t("moodleCourseStatus.importInProgressInfo")}</div>
        <div><Badge bg="success">{t("moodleCourseStatus.created")}</Badge> <br/>{t("moodleCourseStatus.createdInfo")}</div>
      </Popover.Body>
    </Popover>
  );

  const fhsysCourseId = useFhsysCourseId ? query.get('fhsysCourseId'): null;

  return (
    <div>
      {showTeamsLoading && (
        <div
          className="fullMask"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "black",
            opacity: "0.7",
            zIndex: "1",
          }}
        ></div>
      )}
      <h2>{t("menu.manualTitle")}</h2>
      <p style={{ maxWidth: "800px" }}>
        {t("manualCourse.pageDescriptionTop")}
      </p>

      <ul style={{ maxWidth: "800px" }}>
        <li>{t("manualCourse.pageDescriptionBullet1")}</li>
        <li>{t("manualCourse.pageDescriptionBullet2")}</li>
        <li>{t("manualCourse.pageDescriptionBullet3")}</li>
        <li>{t("manualCourse.pageDescriptionBullet4")}</li>
      </ul>
      <p>{t("manualCourse.pageDescriptionBelowBullets")}</p>
      <Table className="table">
        <thead>
          <tr>
            <th scope="col">{t("courseForm.shortNameLabel")}</th>
            <th scope="col">{t("courses.titleHeader")}</th>
            <th scope="col">{t("courses.stgHeader")}</th>
            <th scope="col">{t("courses.semesterHeader")}</th>
            <th scope="col">{t("courses.teacherHeader")}</th>
            <th scope="col">
              {t("courses.moodleHeader")} &nbsp;
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={statusInfoBox}
              >
                <a href="" onClick={(e) => e.preventDefault()}>
                  <FiInfo />
                </a>
              </OverlayTrigger>
            </th>
            <th scope="col">{t("courses.teamsHeader")}</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </Table>
      {courseRequestLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!courseRequestLoading && courses.length <= 0 && (
        <div>{t("courses.noCoursesFound")}</div>
      )}
      <Button
        variant="dark"
        size="sm"
        onClick={() => {
          setUseFhsysCourseId(false);
          handleShow();
        }}
      >
        {t("manualCourse.createCourseButton")}
      </Button>
      <NonCurricularCourseForm
        show={showModal}
        handleClose={handleClose}
        submitLoading={submitLoading}
        handleSubmit={handleSubmit}
        teachers={teachers}
        semesters={semesters}
        stgs={stgs}
        departments={departments}
        fachbereiche={fachbereiche}
        setDefaultUser
        fhsysCourseId={fhsysCourseId}
        
      />
      <Modal
        show={showTeamsLoading}
        onHide={() => {
          setShowTeamsLoading(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Teams</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{t("courses.teamsCourseCreationInProgress")}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
