export default function QuickLinks() {
  return (
    <div style={{ marginRight: "2rem" }}>
      <ul className="rui-iconmenu-sso" style={{ marginBottom: 0 }}>
        <li className="list-inline-item">
          <a
            title="FH Salzburg Moodle"
            href="https://elearn.fh-salzburg.ac.at/"
            target="_blank"
            rel="noopener"
          >
            {" "}
            <img src="https://www.fh-salzburg.ac.at/fileadmin/fhs_daten/abteilungen/is/sso/moodle_tool_off.png" />{" "}
          </a>
        </li>
        <li className="list-inline-item">
          <a
            title="Connect"
            href="https://webmail.fh-salzburg.ac.at/"
            target="_blank"
            rel="noopener"
          >
            {" "}
            <img src="https://www.fh-salzburg.ac.at/fileadmin/fhs_daten/abteilungen/is/sso/connect_tool.png" />{" "}
          </a>
        </li>
        <li className="list-inline-item">
          <a
            title="myFHS"
            href="https://myfhs.fh-salzburg.ac.at/"
            target="_blank"
            rel="noopener"
          >
            {" "}
            <img src="https://www.fh-salzburg.ac.at/fileadmin/fhs_daten/abteilungen/is/sso/intra_tool.png" />{" "}
          </a>
        </li>
        <li className="list-inline-item">
          <a
            title="FHsys"
            href="https://fhsys.fh-salzburg.ac.at/"
            target="_blank"
            rel="noopener"
          >
            {" "}
            <img src="https://www.fh-salzburg.ac.at/fileadmin/fhs_daten/abteilungen/is/sso/fhsys_tool.png" />{" "}
          </a>
        </li>
        <li className="list-inline-item">
          <a
            title="Login Portal"
            href="https://login.fh-salzburg.ac.at/"
            target="_blank"
            rel="noopener"
          >
            {" "}
            <img src="https://www.fh-salzburg.ac.at/fileadmin/fhs_daten/abteilungen/is/sso/portal_tool.png" />{" "}
          </a>
        </li>
        <li className="list-inline-item">
          <a
            title="Library Catalog"
            href="https://search-fsa.obvsg.at/"
            target="_blank"
            rel="noopener"
          >
            {" "}
            <img src="https://www.fh-salzburg.ac.at/fileadmin/fhs_daten/abteilungen/is/sso/alma_primo.png" />{" "}
          </a>
        </li>
      </ul>
    </div>
  );
}
