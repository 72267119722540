import { useState } from "react";
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiChevronDown,
  FiChevronRight,
} from "react-icons/fi";

export default function Tree({ treeData, handleSelect }: any) {
  if (!treeData) {
    return <></>;
  }
  return (
    <ul>
      {treeData.map((node: any) => (
        <TreeNode node={node} key={node.key} handleSelect={handleSelect} />
      ))}
    </ul>
  );
}

function TreeNode({ node, handleSelect }: any) {
  const { children, label } = node;

  const [showChildren, setShowChildren] = useState(false);

  const handleClick = () => {
    if (!children) {
      handleSelect(node);
    }
    setShowChildren(!showChildren);
  };
  return (
    <>
      <div
        onClick={handleClick}
        style={{ cursor: "pointer", marginBottom: "10px" }}
      >
        <a>
          {showChildren ? <FiChevronDown /> : <FiChevronRight />}

          {" " + label}
        </a>
      </div>
      <ul style={{ paddingLeft: "10px", borderLeft: "1px solid black" }}>
        {showChildren && (
          <Tree treeData={children} handleSelect={handleSelect} />
        )}
      </ul>
    </>
  );
}
