import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../userContext";
import { useContext } from "react";

export default function Portal() {
  const { t, i18n } = useTranslation("common");
  const user = useContext(UserContext);
  const teamsUrl =
    user?.teamsRole.search("lba") !== -1 &&
    user?.teamsRole.search("staff") == -1
      ? "/my-courses"
      : "/teams";
  return (
    <Container style={{ maxWidth: "800px" }}>
      <h3>Portal</h3>
      <Row>
        <Col className="d-flex">
          <Card className="flex-grow-1">
            <Card.Body className="d-flex flex-column">
              <Card.Title>Kursbestellungstool</Card.Title>
              <Card.Text className="flex-grow-1">
                <p>
                  Mithilfe des Kursbestellungstools können Sie{" "}
                  <strong>Moodle Kurse und MS Teams</strong> für ihre{" "}
                  <strong>Lehrveranstaltungen</strong> beantragen.
                </p>
                <p>
                  <p>
                    <strong>Wichtige Informationen:</strong>
                  </p>
                  <p>
                    <strong>
                      <i>Für Lehrbeauftrage:</i>
                    </strong>{" "}
                    Teams können ausschließlich im Rahmen von
                    Lehrveranstaltungen im Kursbestellungstool beantragt werden.
                    Wird ein Team außerhalb einer Lehrveranstaltung benötigt,
                    ist das vorher mit dem jeweiligen Department-Office
                    abzustimmen.
                  </p>
                </p>
              </Card.Text>
              <Button
                variant="secondary"
                as="a"
                href="/my-courses"
                style={{ backgroundColor: "#194866" }}
              >
                Zum Kursbestellungstool
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body className="d-flex flex-column">
              <Card.Title>Microsoft Teams Bestellformular</Card.Title>
              <Card.Text className="flex-grow-1">
                <p>
                  Mithilfe dieses Formulars können Sie ein Team für Mircosoft
                  Teams beantragen.
                </p>
                <p>
                  <p>
                    <strong>
                      Wichtige Informationen über das Teams Bestellformular:
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <i>Allgemein:</i>
                    </strong>{" "}
                    Für Teams im Rahmen von Lehrveranstaltungen nutzen Sie bitte
                    das <a href="/my-courses">Kursbestelltool</a>.
                  </p>
                  <p>
                    <strong>
                      <i>Für Lehrbeauftrage:</i>
                    </strong>{" "}
                    Teams können ausschließlich im Rahmen von
                    Lehrveranstaltungen im{" "}
                    <a href="/my-courses">Kursbestelltool</a> beantragt werden.
                    Wird ein Team außerhalb einer Lehrveranstaltung benötigt,
                    ist das vorher mit dem jeweiligen Department-Office
                    abzustimmen.
                  </p>
                  <p>
                    <strong>
                      <i>Für Studierende:</i>
                    </strong>{" "}
                    Teams können ausschließlich im Rahmen von Bachelor- &
                    Masterarbeiten beantragt werden. Hier ist dann auch der
                    jeweilige Betreuer mit anzugeben.
                  </p>
                </p>
              </Card.Text>
              <Button
                variant="primary"
                as="a"
                href={teamsUrl}
                style={{ backgroundColor: "#0d6efd" }}
              >
                Zum Teams Bestellformular
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
