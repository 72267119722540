import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.css";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (window.location.pathname == "/login") {
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  if (token) {
    localStorage.setItem("authToken", token);
    if (localStorage.getItem("redirectUrl")) {
      window.location.href = localStorage.getItem("redirectUrl") as string;
    } else {
      window.location.href = "/";
    }
  }
}

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false }, // React already does escaping
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    de: {
      common: common_de,
    },
  },
});

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
