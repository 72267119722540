// @ts-nocheck

import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Stg } from "../../entities/stg";
import { Typeahead } from "react-bootstrap-typeahead";
import { Semester } from "../../entities/semester";
import { Teacher } from "../../entities/teacher";
import { CourseTemplate } from "../../entities/courseTemplate";
import * as formik from "formik";
import * as yup from "yup";
import { Department } from "../../entities/department";
import { Fachbereich } from "../../entities/fachbereich";
import { UserContext } from "../../userContext";
import { useTranslation } from "react-i18next";
import { getRemoteOldCourseTemplatesForCurrentUser } from "../../services/api";

export default function NonCurricularCourseForm(props: any) {
  const { t, i18n } = useTranslation("common");
  const {
    show,
    handleClose,
    handleSubmit,
    stgs,
    semesters,
    teachers,
    departments,
    fachbereiche,
    submitLoading,
    setDefaultUser,
  } = props;

  const [selectedStg, setSelectedStg] = useState<any[]>([]);
  const stgInputValues = stgs.map((stg: Stg) => stg.name);

  const [selectedOldCourseTemplate, setSelectedOldCourseTemplate] = useState<
    any[]
  >([]);
  const [selectedCoTeachers, setSelectedCoTeachers] = useState<any[]>([]);
  const [selectedNonEditingTeachers, setSelectedNonEditingTeachers] = useState<
    any[]
  >([]);
  const [selectedDepartment, setSelectedDepartment] = useState<any>();

  const [oldCourseTemplates, setOldCourseTemplates] = useState<Stg[]>([]);

  const [hiddenTeacherComment, setHiddenTeacherComment] = useState<string>("");
  const [courseTemplateNotFoundInput, setCourseTemplateNotFoundInput] =
    useState<string>("");

  const semesterOptions = semesters.map((semester: Semester) => (
    <option
      key={semester.wsSs + semester.year}
      value={semester.wsSs + semester.year}
    >
      {semester.wsSs} {semester.year}
    </option>
  ));
  const departmentValues = departments.map(
    (department: Department) => department.id
  );
  const semesterValues = semesters.map(
    (semester: Semester) => semester.wsSs + semester.year
  );
  semesterValues.push("no-semester");
  const departmentOptions = departments.map((department: Department) => (
    <option key={department.id} value={department.id}>
      {department.departmentGerman}
    </option>
  ));
  const fachbereichOptions = fachbereiche.map((fachbereich: Fachbereich) => {
    if (selectedDepartment && selectedDepartment == fachbereich.departmentId) {
      return (
        <option key={fachbereich.id} value={fachbereich.id}>
          {fachbereich.fachbereichGerman}
        </option>
      );
    }
    return null;
  });

  const [selectedTeachers, setSelectedTeachers] = useState<any[]>([]);
  const teachersInputValues = teachers.map((teacher: Teacher) => {
    return {
      id: teacher.username,
      name: teacher.firstname + " " + teacher.lastname,
    };
  });

  const oldCoursesPrefix = t('courseForm.oldCoursesPrefix');
  const oldCourseTemplateInputValues = oldCourseTemplates.map(
    (courseTemplate: CourseTemplate) => {
      let courseTitle = courseTemplate.courseTitle;
      if (courseTemplate.source == 'oldMoodle') {
        courseTitle = oldCoursesPrefix + " " + courseTemplate.courseTitle;
      }

      return {
        id: courseTemplate.courseId,
        name: courseTitle,
        courseShortTitle: courseTemplate.courseShortTitle,
      };
    }
  );

  const [wizardStep, setWizardStep] = useState<number>(0);

  const nextWizardStep = () => {
    if (wizardStep < 4) {
      setWizardStep(wizardStep + 1);
    }
  };
  const previousWizardStep = () => {
    if (wizardStep > 0) {
      setWizardStep(wizardStep - 1);
    }
  };

  const getClassNameForWizardStep = (step: number) => {
    var className = "wizardStep";
    if (step === wizardStep) {
      className += " active";
    }
    return className;
  };

  const getOldCourseTemplates = async () => {
    await handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
      selectedTeachers,
      selectedCoTeachers
    );
  };
  const handleInitialGetRemoteOldCourseTemplatesForCurrentUser = async (
    courseTeachers: any,
    courseCoTeachers: any
  ) => {
    const teachers = [];
    for (const teacher of courseTeachers) {
      teachers.push(teacher.id);
    }
    for (const teacher of courseCoTeachers) {
      teachers.push(teacher.id);
    }
    const data = await getRemoteOldCourseTemplatesForCurrentUser(teachers);
    setOldCourseTemplates(data);
  };

  const user = useContext(UserContext);
  useEffect(() => {
    if (setDefaultUser) {
      setSelectedTeachers([
        { id: user?.username, name: user.firstname + " " + user.lastname },
      ]);
      handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
        [{ id: user?.username }],
        []
      );
    } else {
      getOldCourseTemplates();
    }
  }, []);

  const { Formik } = formik;

  const schema = yup.object().shape({
    lvaNumber: yup.string().required(t("courseForm.lvaNumberRequiredMessage")),
    titleMoodle: yup.string().required(t("courseForm.lvaTitleRequiredMessage")),
    department: yup
      .string()
      .required(t("courseForm.departmentRequiredMessage"))
      .oneOf(departmentValues, t("courseForm.departmentRequiredMessage")),
    teacherComment: yup
      .string()
      .required(t("courseForm.reasonRequiredMessage")),
    teachers: yup
      .string()
      .required(t("courseForm.courseInstructorRequiredMessage"))
      .notOneOf(["[]"], t("courseForm.courseInstructorRequiredMessage")),
    semester: yup
      .string()
      .required(t("courseForm.semesterRequiredMessage"))
      .oneOf(semesterValues, t("courseForm.semesterRequiredMessage")),
  });

  return (
    <Modal
      backdrop='static'
      show={show}
      onHide={() => {
        setWizardStep(0);
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("manualCourse.createCourseButton")}</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          if (selectedOldCourseTemplate.length) {
            values.templateOldCourseId = selectedOldCourseTemplate[0].id;
          }
          values.stg = selectedStg[0];
          values.teachers = JSON.stringify(selectedTeachers);
          values.coTeachers = JSON.stringify(selectedCoTeachers);
          values.nonEditingTeachers = JSON.stringify(
            selectedNonEditingTeachers
          );
          const courseValues = { ...values };
          if (hiddenTeacherComment) {
            courseValues.teacherComment =
              hiddenTeacherComment + "\n" + values.teacherComment;
          }
          handleSubmit(courseValues).then(() => {
            setWizardStep(0);
            setSelectedCoTeachers([]);
            setSelectedNonEditingTeachers([]);
            setSelectedStg([]);
            setSelectedOldCourseTemplate([]);
          });
        }}
        initialValues={{
          lvaNumber: "",
          titleMoodle: "",
          department: "",
          stg: "",
          fachbereich: "",
          stgType: "",
          orgType: "",
          semester: "",
          teachers: JSON.stringify(selectedTeachers),
          descriptionMoodle: "",
          coTeachers: "",
          nonEditingTeachers: "",
          templateOldCourseId: "",
          teacherComment: "",
          moodleCourseStatus: "requested",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          validateField,
          setFieldError,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className={getClassNameForWizardStep(0)}>
                <Form.Group className="mb-3" controlId="courseForm.lvNumber">
                  <Form.Label>{t("courseForm.shortNameLabel")}*</Form.Label>
                  <Form.Control
                    autoFocus
                    type="text"
                    name="lvaNumber"
                    value={values.lvaNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.lvaNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lvaNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="courseForm.title">
                  <Form.Label>{t("courseForm.titleLabel")}*</Form.Label>
                  <Form.Control
                    type="text"
                    name="titleMoodle"
                    value={values.titleMoodle}
                    onChange={handleChange}
                    isInvalid={!!errors.titleMoodle}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.titleMoodle}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="courseForm.department">
                  <Form.Label>{t("courseForm.departmentLabel")}*</Form.Label>
                  <Form.Select
                    aria-label={t("courseForm.chooseDepartment")}
                    name="department"
                    value={values.department}
                    isInvalid={!!errors.department}
                    onChange={(event: any) => {
                      handleChange(event);
                      setSelectedDepartment(event.target.value);
                    }}
                  >
                    <option>{t("courseForm.chooseDepartment")}</option>
                    {departmentOptions}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.department}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="courseForm.stg">
                  <Form.Label>{t("courseForm.stgLabel")}</Form.Label>
                  <Typeahead
                    id="courseForm.stg2"
                    labelKey="name"
                    onChange={setSelectedStg}
                    options={stgInputValues}
                    placeholder={t("courseForm.pleaseChoose")}
                    selected={selectedStg}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="courseForm.fachbereich">
                  <Form.Label>{t("courseForm.fachbereichLabel")}</Form.Label>
                  <Form.Select
                    aria-label={t("courseForm.pleaseChoose")}
                    name="fachbereich"
                    value={values.fachbereich}
                    onChange={handleChange}
                  >
                    <option>{t("courseForm.pleaseChoose")}</option>
                    {fachbereichOptions}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="courseForm.semester">
                  <Form.Label>{t("courseForm.semesterLabel")}</Form.Label>
                  <Form.Select
                    aria-label={t("courseForm.pleaseChoose")}
                    name="semester"
                    value={values.semester}
                    onChange={handleChange}
                    isInvalid={!!errors.semester}
                  >
                    <option>{t("courseForm.pleaseChoose")}</option>
                    <option key="no-semester" value="no-semester">{t("courseForm.noSemester")}</option>
                    {semesterOptions}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.semester}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Form.Group className="mb-3" controlId="courseForm.semester">
                    <Form.Label>
                      {t("courseForm.mandatoryFieldInfoText")}
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    onClick={() => {
                      validateField("lvaNumber");
                      validateField("titleMoodle");
                      validateField("department");
                      validateField("semester");
                      if (
                        values.lvaNumber != "" &&
                        !errors.lvaNumber &&
                        values.titleMoodle != "" &&
                        !errors.titleMoodle &&
                        values.department != "" &&
                        !errors.department &&
                        values.semester != "" &&
                        !errors.semester
                      ) {
                        nextWizardStep();
                      }
                    }}
                  >
                    {t("courseForm.wizzardNext")}
                  </Button>
                </div>
              </div>
              <div className={getClassNameForWizardStep(1)}>
                <Form.Group className="mb-3" controlId="courseForm.teacher">
                  <Form.Label>
                    {t("courseForm.courseInstructorLabel")}*
                  </Form.Label>
                  <p>{t("courseForm.courseInstructorInfo")}</p>
                  <Typeahead
                    id="courseForm.teacher"
                    labelKey="name"
                    multiple
                    onChange={(selected) => {
                      setSelectedTeachers(selected);
                      setFieldValue("teachers", JSON.stringify(selected));
                      handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
                        selected,
                        selectedCoTeachers
                      );
                    }}
                    options={teachersInputValues}
                    placeholder={t("courseForm.pleaseChoose")}
                    selected={selectedTeachers}
                    isInvalid={!!errors.teachers}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.teachers}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("courseForm.teacherLabel")}</Form.Label>
                  <Typeahead
                    id="courseForm.coTeacher"
                    labelKey="name"
                    multiple
                    onChange={(selected) => {
                      setSelectedCoTeachers(selected);
                      handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
                        selectedTeachers,
                        selected
                      );
                    }}
                    options={teachersInputValues}
                    placeholder={t("courseForm.pleaseChoose")}
                    selected={selectedCoTeachers}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("courseForm.nonEditingTeachers")}</Form.Label>
                  <Typeahead
                    id="courseForm.nonEditingTeacher"
                    labelKey="name"
                    multiple
                    onChange={setSelectedNonEditingTeachers}
                    options={teachersInputValues}
                    placeholder={t("courseForm.pleaseChoose")}
                    selected={selectedNonEditingTeachers}
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Form.Group className="mb-3" controlId="courseForm.semester">
                    <Form.Label>
                      {t("courseForm.mandatoryFieldInfoText")}
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      previousWizardStep();
                    }}
                  >
                    {t("courseForm.wizzardPrevious")}
                  </Button>
                  <Button
                    variant="primary"
                    className="ms-2"
                    onClick={() => {
                      validateField("teachers");
                      if (values.teachers != "" && !errors.teachers) {
                        nextWizardStep();
                      }
                    }}
                  >
                    {t("courseForm.wizzardNext")}
                  </Button>
                </div>
              </div>
              <div className={getClassNameForWizardStep(2)}>
                <Form.Group className="mb-3" controlId="courseForm.description">
                  <Form.Label>{t("courseForm.courseDescription")}</Form.Label>
                  <p>{t("courseForm.manualCourseDescriptionInfo")}</p>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="descriptionMoodle"
                    value={values.descriptionMoodle}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("courseForm.courseTemplateLabel")}</Form.Label>
                  <p>{t("courseForm.chooseTemplateInfoText1")}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("courseForm.chooseTemplateInfoText2", {
                        linkStart: "<a href='' target='_blank'>",
                        linkEnd: "</a>",
                      }),
                    }}
                  ></p>
                  <Typeahead
                    id="courseForm.courseTemplate"
                    labelKey="name"
                    onChange={setSelectedOldCourseTemplate}
                    options={oldCourseTemplateInputValues}
                    placeholder={t("courseForm.chooseTemplateLabel")}
                    selected={selectedOldCourseTemplate}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("courseForm.courseTemplateNotFoundLabel")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="courseTemplateNotFound"
                    value={courseTemplateNotFoundInput}
                    onChange={(event) => {
                      setCourseTemplateNotFoundInput(event.target.value);
                      if (event.target.value) {
                        setHiddenTeacherComment(
                          "Kurstemplate wurde nicht gefunden: " +
                            event.target.value
                        );
                      } else {
                        setHiddenTeacherComment("");
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="courseForm.teacherComment"
                >
                  <Form.Label>{t("courseForm.reasonLabel")}*</Form.Label>
                  <p>{t("courseForm.reasonDescription")}</p>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="teacherComment"
                    value={values.teacherComment}
                    onChange={handleChange}
                    isInvalid={!!errors.teacherComment}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.teacherComment}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Form.Group className="mb-3" controlId="courseForm.mandatoryFieldInfo">
                    <Form.Label>
                      {t("courseForm.mandatoryFieldInfoText")}
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      previousWizardStep();
                    }}
                  >
                    {t("courseForm.wizzardPrevious")}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={submitLoading}
                    className="ms-2"
                  >
                    {submitLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {t("courseForm.createButton")}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
