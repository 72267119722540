import { Badge } from "react-bootstrap";
import CourseStgType from "../../components/CourseStgType";
import { useContext } from "react";
import { UserContext } from "../../userContext";
import { useTranslation } from "react-i18next";
import { resolveTemplateSyncError } from "../../services/api";

export default function CourseListItem(props: any) {
  const { t, i18n } = useTranslation("common");
  const {
    course,
    setCurrentCourse,
    handleShow,
    handleCreateTeam,
    handleReloadCourses,
  } = props;
  const user = useContext(UserContext);
  const fhsysTeachers = course.fhsysTeachers.map((teacher: any, i: any) => {
    return (
      <div key={course.id + "_" + teacher.id}>
        <strong>{teacher.name}</strong>
      </div>
    );
  });
  const fhsysCoTeachers = course.fhsysCoTeachers.map((teacher: any, i: any) => {
    return <div key={course.id + "_" + teacher.id}>{teacher.name}</div>;
  });
  const courseInstructorRow = () => {
    if (user?.departments.length || user?.fachbereiche.length) {
      return (
        <td>
          {fhsysTeachers}
          {fhsysCoTeachers}
        </td>
      );
    }
    return <></>;
  };

  const handleTemplateSyncErrorResolved = async (course: any) => {
    const formData = new FormData();
    formData.append("fhsysCourseId", course.id);
    await resolveTemplateSyncError(formData);
    handleReloadCourses();
  };
  const userAllowedToResolve = user?.role == "admin" || user?.originalRole == "admin";

  return (
    <tr>
      <td>{course.departmentGerman}</td>
      <td>{course.stg}</td>
      <td>{course.fachbereichGerman}</td>
      <td>
        {course.wsSs} {course.year}
      </td>
      {courseInstructorRow()}
      <td>{course.id}</td>
      <td>{course.titleGerman}</td>
      <td>{course.lvaType}</td>
      <td>
        {course.moodleCourseStatus == "not_requested" && (
          <a
            href="#"
            className="link-primary"
            onClick={(e) => {
              e.preventDefault();
              setCurrentCourse(course);
              handleShow();
            }}
          >
            {t("courseList.requestMoodleCourse")}
          </a>
        )}
        {course.moodleCourseStatus == "requested" && (
          <Badge bg="warning">{t("moodleCourseStatus.inProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "accepted" && (
          <Badge bg="success">{t("moodleCourseStatus.created")}</Badge>
        )}
        {course.moodleCourseStatus == "rejected" && (
          <Badge bg="dark">{t("moodleCourseStatus.rejected")}</Badge>
        )}
        {course.moodleCourseStatus == "waitingForTemplateSync" && (
          <Badge bg="info">{t("moodleCourseStatus.importInProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "templateSyncError" && (
          <>
            <Badge bg="danger">{t("moodleCourseStatus.importError")}</Badge>
            {userAllowedToResolve && (
              <div className="mt-2">
                <a
                  href="#"
                  onClick={(event: any) => {
                    event.preventDefault();
                    handleTemplateSyncErrorResolved(course);
                  }}
                >
                  {t("errorResolved")}
                </a>
              </div>
            )}
          </>
        )}
      </td>
      <td>
        {course.teamsCourseStatus == "not_requested" && (
          <a
            href="#"
            className="link-primary"
            onClick={() => {
              handleCreateTeam(course);
            }}
          >
            {t("courseList.requestTeamsCourse")}
          </a>
        )}
        {course.teamsCourseStatus == "requested" && (
          <Badge bg="warning">{t("teamsCourseStatus.inProgress")}</Badge>
        )}
        {course.teamsCourseStatus == "accepted" && (
          <Badge bg="success">{t("teamsCourseStatus.created")}</Badge>
        )}
      </td>
    </tr>
  );
}
