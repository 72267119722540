import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import * as formik from "formik";
import * as yup from "yup";
import { useContext, useState } from "react";
import { requestTeam } from "../../services/api";
import { Alert, Modal, NavDropdown } from "react-bootstrap";
import { UserContext } from "../../userContext";

export default function RequestTeamForm() {
  const user = useContext(UserContext);
  const { Formik } = formik;
  const [selectedTeamType, setSelectedTeamType] = useState<
    "stud" | "project" | "team" | "event" | undefined
  >(user?.teamsRole.search("student") !== -1 ? "stud" : "project");
  const [showSuccess, setShowSuccess] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const schema = yup.object().shape({
    userLastName: yup.string().required("Name ist erforderlich"),
    userFirstName: yup.string().required("Vorname ist erforderlich"),
    userMail: yup.string().required("E-Mail ist erforderlich"),
    teamType: yup.string().required("Team Art ist erforderlich"),
    teamName: yup
      .string()
      .required("Team Name ist erforderlich")
      .max(100, "Team Name darf maximal 100 Zeichen lang sein"),
    supervisor: yup.string(),
    teamReason: yup.string().required("Begründung ist erforderlich"),
  });

  const validate = (values: any) => {
    const errors: any = {};
    if (selectedTeamType === "stud" && !values.supervisor) {
      errors.supervisor = "Bitte Betreuer angeben";
    }
    return errors;
  };

  const handleSubmit = async (newTeam: any) => {
    const formData = new FormData();
    formData.append("userLastName", newTeam.userLastName);
    formData.append("userFirstName", newTeam.userFirstName);
    formData.append("userMail", newTeam.userMail);
    formData.append("teamType", newTeam.teamType);
    formData.append("teamName", newTeam.teamName);
    formData.append("supervisor", newTeam.supervisor);
    formData.append("teamReason", newTeam.teamReason);
    const response = await requestTeam(formData);
    if (response.data && response.data.success) {
      setWarningMessage("");
      setShowSuccess(true);
    } else if (
      response.data &&
      response.data.error &&
      response.data.error == "teamAlreadyExists"
    ) {
      setShowSuccess(false);
      setWarningMessage(
        "Der Kursname ist bereits vergeben. Bitte wählen Sie einen anderen."
      );
    }
  };

  const isStaffOrLba =
    user?.teamsRole.search("staff") !== -1 ||
    user?.teamsRole.search("lba") !== -1;

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Team beantragen</h2>
      </div>
      {showSuccess && (
        <Alert variant="success">
          Ihr Antrag wird geprüft. Sobald die Prüfung erfolgt ist, erhalten Sie
          eine Mail. Die Prüfung erfolgt in der Regel innerhalb von 1-2
          Arbeitstagen.
        </Alert>
      )}

      {warningMessage && (
        <Alert variant="danger">
          <p>{warningMessage}</p>
        </Alert>
      )}
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validate={validate}
        initialValues={{
          userLastName: user?.firstname,
          userFirstName: user?.lastname,
          userMail: user?.username,
          teamType: selectedTeamType,
          teamName: "",
          supervisor: "",
          teamReason: "",
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            onSubmit={(event) => {
              handleSubmit(event);
            }}
          >
            <Form.Group className="mb-3" controlId="teamForm.userLastName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="userLastName"
                value={values.userLastName}
                disabled
                isInvalid={!!errors.userLastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.userLastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="teamForm.userFirstName">
              <Form.Label>Vorname</Form.Label>
              <Form.Control
                type="text"
                name="userFirstName"
                value={values.userFirstName}
                isInvalid={!!errors.userFirstName}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {errors.userFirstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="teamForm.userMail">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                type="mail"
                name="userMail"
                value={values.userMail}
                isInvalid={!!errors.userMail}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {errors.userMail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="courseForm.teamType">
              <Form.Label>Team Art</Form.Label>
              <Form.Select
                name="teamType"
                value={values.teamType}
                isInvalid={!!errors.teamType}
                onChange={(event: any) => {
                  handleChange(event);
                  setSelectedTeamType(event.target.value);
                }}
              >
                <>
                  {user?.teamsRole.search("student") !== -1 && (
                    <option value="stud">Student</option>
                  )}
                  {isStaffOrLba && (
                    <>
                      <option value="project">Projekte</option>
                      <option value="event">Veranstaltungen</option>
                      <option value="team">Sonstige</option>
                    </>
                  )}
                </>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.teamType}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Label>Team Name</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="formBasicTeamName">
                {!!selectedTeamType && selectedTeamType.toUpperCase()}-
              </InputGroup.Text>
              <Form.Control
                name="teamName"
                value={values.teamName}
                onChange={handleChange}
                isInvalid={!!errors.teamName}
                placeholder="Namensvorschlag für das Team"
              />
              <Form.Control.Feedback type="invalid">
                {errors.teamName}
              </Form.Control.Feedback>
            </InputGroup>

            {selectedTeamType === "stud" && (
              <>
                <Form.Label>Betreuer</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="supervisor"
                    value={values.supervisor}
                    onChange={handleChange}
                    isInvalid={!!errors.supervisor}
                    placeholder="Ihr Betreuer"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.supervisor}
                  </Form.Control.Feedback>
                </InputGroup>
              </>
            )}

            <Form.Group className="mb-3" controlId="teamForm.teamReason">
              <Form.Label>Begründung</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="teamReason"
                onChange={handleChange}
                value={values.teamReason}
                isInvalid={!!errors.teamReason}
              />
              <Form.Control.Feedback type="invalid">
                {errors.teamReason}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Team beantragen
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}
