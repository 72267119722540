import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Info() {
  const { t, i18n } = useTranslation("common");
  return (
    <Container style={{ maxWidth: "550px" }}>
      <h3>Info</h3>
      <p>{t("info.generalInfo")}</p>
      <p>
        {t("info.moodleManualInfoText")} <a>{t("moodleManual")}</a>.
      </p>
      <p
        dangerouslySetInnerHTML={{
          __html: t("info.helpInfoText", {
            mailContact:
              "<a href='mailto:elearning@fh-salzburg.ac.at'>elearning@fh-salzburg.ac.at</a>",
          }),
        }}
      ></p>
    </Container>
  );
}
