import { Modal } from "react-bootstrap";
import Tree from "../../components/Tree";
import { useTranslation } from "react-i18next";

export default function MoodleCategoryChanger(props: any) {
  const {t, i18n} = useTranslation('common');
  const {
    show,
    handleClose,
    setSelectedMoodleCategory,
    categories,
    department,
    fachbereich,
    semester,
  } = props;

  const handleSelect = (selected: any) => {
    setSelectedMoodleCategory(selected);
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {t('courseForm.departmentLabel')}: <strong>{department}</strong>
          <br />
          {t('courseForm.fachbereichLabel')}: <strong>{fachbereich}</strong>
          <br />
          {t('courseForm.semesterLabel')}: <strong>{semester}</strong>
        </div>
        <hr />
        <div className="mb-3">{t('courseForm.chooseCategoryHere')}</div>
        <Tree treeData={categories} handleSelect={handleSelect} />
      </Modal.Body>
    </Modal>
  );
}
