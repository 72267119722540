import axios from "axios";
import { RequestedTeam } from "../entities/RequestedTeam";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const authToken = localStorage.getItem("authToken");
const headers = {
  Authorization: `Bearer ${authToken}`,
};
const config = { headers };

export const getRemoteCurrentUser = async () => {
  try {
    const request = await axios.get(apiBaseUrl + "/api/me", config);
    return request.data;
  } catch (error: any) {
    return error.response.data;
  }
};
export const getRemoteCoursesForCurrentUser = async () => {
  try {
    const request = await axios.get(apiBaseUrl + "/api/me/courses", config);
    return request.data;
  } catch (error: any) {
    return error.response.data;
  }
};
export const getRemoteManualCoursesForCurrentUser = async () => {
  const { data } = await axios.get(
    apiBaseUrl + "/api/me/manual-courses",
    config
  );
  return data;
};
export const getRemoteOldCourseTemplatesForCurrentUser = async (
  teachers: any = []
) => {
  const teachersString = JSON.stringify(teachers);
  const { data } = await axios.get(
    apiBaseUrl + "/api/me/old-courses?teachers=" + teachersString,
    config
  );
  return data;
};

export const getRemoteTeachers = async () => {
  const { data } = await axios.get(apiBaseUrl + "/api/teachers", config);
  return data;
};
export const getRemoteSemesters = async () => {
  const { data } = await axios.get(apiBaseUrl + "/api/semesters", config);
  return data;
};
export const getRemoteStgs = async () => {
  const { data } = await axios.get(apiBaseUrl + "/api/stgs", config);
  return data;
};
export const getRemoteDepartments = async () => {
  const { data } = await axios.get(apiBaseUrl + "/api/departments", config);
  return data;
};
export const getRemoteFachbereiche = async () => {
  const { data } = await axios.get(apiBaseUrl + "/api/fachbereiche", config);
  return data;
};
export const getRemoteCategories = async () => {
  const { data } = await axios.get(apiBaseUrl + "/api/categories", config);
  return data;
};
export const getRemoteActiveRequestedCourses = async () => {
  const { data } = await axios.get(
    apiBaseUrl + "/api/all-requested-courses",
    config
  );
  return data;
};
export const getRemoteCompletedRequestedCourses = async () => {
  const { data } = await axios.get(
    apiBaseUrl + "/api/all-requested-courses?isCompleted=1",
    config
  );
  return data;
};
export const getRemoteManualCourseByCourseId = async (
  courseId: string | undefined
) => {
  if (courseId) {
    try {
      const request = await axios.get(
        apiBaseUrl + "/api/manual-courses/" + courseId,
        config
      );
      return request.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
};
export const getRemoteCourseByCourseId = async (
  courseId: string | undefined
) => {
  if (courseId) {
    try {
      const request = await axios.get(
        apiBaseUrl + "/api/courses/" + courseId,
        config
      );
      return request.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
};

export const createRemoteCourse = async (formData: FormData) => {
  await axios({
    method: "post",
    url: apiBaseUrl + "/api/courses",
    data: formData,
    headers,
  });
};
export const requestRemoteCourse = async (formData: FormData) => {
  try {
    await axios({
      method: "post",
      url: apiBaseUrl + "/api/manual-courses",
      data: formData,
      headers,
    });
  } catch (error: any) {
    return error.response.data;
  }
};
export const createRemoteManualMoodleCourse = async (formData: FormData) => {
  try {
    await axios({
      method: "post",
      url: apiBaseUrl + "/api/manual-moodle-courses",
      data: formData,
      headers,
    });
  } catch (error: any) {
    return error.response.data;
  }
};
export const changeRemoteUser = async (formData: FormData) => {
  try {
    const { data } = await axios({
      method: "post",
      url: apiBaseUrl + "/api/change-user",
      data: formData,
      headers,
    });
    return data;
  } catch (error: any) {
    console.error(error.response.data);
  }
};

export const createRemoteTeams = async (formData: FormData) => {
  await axios({
    method: "post",
    url: apiBaseUrl + "/api/teams",
    data: formData,
    headers,
  });
};

export const resolveTemplateSyncError = async (formData: FormData) => {
  await axios({
    method: "post",
    url: apiBaseUrl + "/api/resolve-template-sync-error",
    data: formData,
    headers,
  });
};

export const rejectRemoteRequestedCourse = async (formData: FormData) => {
  await axios({
    method: "post",
    url: apiBaseUrl + "/api/reject-requested-course",
    data: formData,
    headers,
  });
};

export const getRemoteGlobalInfoText = async () => {
  const { data } = await axios.get(
    apiBaseUrl + "/api/settings/global_info_message",
    config
  );
  return data;
};
export const setRemoteGlobalInfoText = async (formData: FormData) => {
  await axios({
    method: "post",
    url: apiBaseUrl + "/api/settings/global_info_message",
    data: formData,
    headers,
  });
};

export type FilterType = {
  teamType: string;
  status: string;
  userName: string;
};
export const getAllRequestedTeams = async (filter: FilterType) => {
  const { data } = await axios.get(
    apiBaseUrl +
      "/api/request-teams" +
      "?userName=" +
      filter.userName +
      "&teamType=" +
      filter.teamType +
      "&status=" +
      filter.status,
    config
  );
  return data as RequestedTeam[];
};
export const requestTeam = async (formData: FormData) => {
  return await axios({
    method: "post",
    url: apiBaseUrl + "/api/request-teams",
    data: formData,
    headers,
  });
};

export const editTeam = async (formData: FormData) => {
  await axios({
    method: "post",
    url: apiBaseUrl + "/api/edit-request-team",
    data: formData,
    headers,
  });
};
