import React, { useEffect, useState } from "react";
import TopNavbar, { topNavbarVersions } from "./components/TopNavbar";
import { Routes, Route, useLocation } from "react-router-dom";
import Courses from "./pages/courses/Courses";
import NotFound from "./pages/NotFound";
import Menu from "./components/Menu";
import { Button, Col, Modal, Row } from "react-bootstrap";
import CoursesAdmin from "./pages/courses-admin/CoursesAdmin";
import CoursesManual from "./pages/courses-manual/CoursesManual";
import { getRemoteCurrentUser, getRemoteGlobalInfoText } from "./services/api";
import { User } from "./entities/user";
import { UserContext } from "./userContext";
import CoursesAdminDetail from "./pages/courses-admin/CoursesAdminDetail";
import { useTranslation } from "react-i18next";
import { useErrorStore } from "./errorStore";
import Info from "./pages/Info";
import "./App.css";
import GlobalInfoBox from "./components/GlobalInfoBox";
import Settings from "./pages/Settings";
import CompletedCoursesAdmin from "./pages/courses-admin/CompletedCoursesAdmin";
import Teams from "./pages/teams/Teams";
import TeamsMenu from "./components/TeamsMenu";
import TeamsAdmin from "./pages/teams-admin/TeamsAdmin";
import Portal from "./pages/portal/Portal";

function App() {
  const [currentUser, setCurrentUser] = useState<User>();
  const [globalInfoText, setGlobalInfoText] = useState<string>("");
  const { t, i18n } = useTranslation("common");
  const location = useLocation();
  const { pathname } = location;

  const getCurrentUser = async () => {
    const data = await getRemoteCurrentUser();
    if (data.status === 403) {
      //save the current url in local storage to be able to redirect after login
      localStorage.setItem("redirectUrl", pathname);

      //no valid user -> start login!
      window.location.href = process.env.REACT_APP_API_BASE_URL + "/api/login";
    } else {
      if (
        !pathname.startsWith("/teams") &&
        (data.role === "admin" ||
          data.role === "office" ||
          data.role === "teacher")
      ) {
        setCurrentUser(data);
      } else if (pathname.startsWith("/teams") && data.teamsRole) {
        setCurrentUser(data);
      } else {
        localStorage.removeItem("authToken");
        setErrorMessage(t("accessDenied"));
      }
    }
  };

  const getGlobalInfoText = async () => {
    const data = await getRemoteGlobalInfoText();
    if (data) {
      setGlobalInfoText(data);
    }
  };

  const handleUserChange = (user: User) => {
    setCurrentUser(user);
    window.location.href = "/";
  };

  useEffect(() => {
    getCurrentUser();
    getGlobalInfoText();
  }, []);

  const errorMessage = useErrorStore((state) => state.errorMessage);
  const setErrorMessage = useErrorStore((state) => state.setErrorMessage);

  if (!currentUser && errorMessage) {
    return <>{errorMessage}</>;
  }
  if (!currentUser) {
    return <>{t("global.loading")}</>;
  }

  const getCurrentTopNavbarVersion = () => {
    if (pathname.startsWith("/teams")) {
      return topNavbarVersions.teams;
    } else if (pathname == "/") {
      return topNavbarVersions.portal;
    }
    return topNavbarVersions.default;
  };

  const currentTopNavbarVersion = getCurrentTopNavbarVersion();

  return (
    <UserContext.Provider value={currentUser || null}>
      <div className="App">
        <TopNavbar
          handleUserChange={handleUserChange}
          version={currentTopNavbarVersion}
        />
        <Row style={{ marginRight: 0, marginLeft: 0, flexGrow: 1 }}>
          {currentTopNavbarVersion !== topNavbarVersions.portal && (
            <Col
              md={3}
              lg={2}
              className="sidebar d-md-block bg-light sidebar collapse"
            >
              {currentTopNavbarVersion === topNavbarVersions.teams && (
                <TeamsMenu />
              )}
              {currentTopNavbarVersion === topNavbarVersions.default && (
                <Menu />
              )}
            </Col>
          )}
          <Col className="px-md-4 pt-4">
            {!!globalInfoText &&
              currentTopNavbarVersion === topNavbarVersions.default && (
                <div className="globalInfoBox" style={{ maxWidth: "800px" }}>
                  <GlobalInfoBox globalInfoText={globalInfoText} />
                </div>
              )}
            <Routes>
              <Route
                path="/"
                element={<Portal key={currentUser?.username} />}
              />
              <Route
                path="/my-courses"
                element={<Courses key={currentUser?.username} />}
              />
              <Route
                path="/courses-manual"
                element={<CoursesManual key={currentUser?.username} />}
              />
              <Route
                path="/courses-admin"
                element={<CoursesAdmin key={currentUser?.username} />}
              />
              <Route
                path="/completed-courses-admin"
                element={<CompletedCoursesAdmin key={currentUser?.username} />}
              />
              <Route
                path="/courses-admin/:courseId"
                element={<CoursesAdminDetail key={currentUser?.username} />}
              />
              <Route
                path="/info"
                element={<Info key={currentUser?.username} />}
              />
              <Route
                path="/settings"
                element={<Settings key={currentUser?.username} />}
              />
              <Route path="/teams" element={<Teams />} />
              <Route path="/teams-admin" element={<TeamsAdmin />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Col>
        </Row>
        <Modal
          show={!!errorMessage}
          onHide={() => {
            setErrorMessage("");
          }}
          backdropClassName="globalErroBackdrop"
          className="globalErrorModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("error")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{errorMessage}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setErrorMessage("");
              }}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </UserContext.Provider>
  );
}

export default App;
