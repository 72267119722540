import { Fragment, useEffect, useState } from "react";
import {
  Button,
  CloseButton,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
  Toast,
  ToastBody,
} from "react-bootstrap";
import {
  Typeahead,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import { Semester } from "../../entities/semester";
import { Stg } from "../../entities/stg";
import { Teacher } from "../../entities/teacher";

import "./CourseForm.css";
import { CourseTemplate } from "../../entities/courseTemplate";
import { FiInfo } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useErrorStore } from "../../errorStore";
import { getRemoteOldCourseTemplatesForCurrentUser } from "../../services/api";

export default function CourseForm(props: any) {
  const { t, i18n } = useTranslation("common");
  const navigate = useNavigate();
  const setErrorMessage = useErrorStore((state) => state.setErrorMessage);

  const {
    show,
    handleClose,
    handleSubmit,
    currentCourse,
    submitLoading,
    teachers,
    semesters,
    stgs,
  } = props;
  const teachersInputValues = teachers.map((teacher: Teacher) => {
    return {
      id: teacher.username,
      name: teacher.firstname + " " + teacher.lastname,
    };
  });

  const oldCourseTemplateMenuItems = (
    results: any,
    {
      newSelectionPrefix,
      paginationText,
      renderMenuItemChildren,
      ...menuProps
    }: any,
    state: any
  ) => {
    const searchTitle = currentCourse.lvaNumber.slice(-5);
    let countSimilarCourses = 0;
    let countOtherCourses = 0;
    const regex = new RegExp(searchTitle);
    const sortedTemplates = results.map((template: any) => {
      let isSimilarCourse = false;
      if (
        template.courseShortTitle &&
        template.courseShortTitle.search(regex) != -1
      ) {
        isSimilarCourse = true;
        countSimilarCourses += 1;
      } else {
        countOtherCourses += 1;
      }
      return {
        similarCourse: isSimilarCourse,
        id: template.id,
        courseShortTitle: template.courseShortTitle,
        name: template.name,
      };
    });
    let indexSimilar = 0;
    let indexOther = 0;
    let index = 0;
    const items = (
      <>
        <Fragment key="similarCourses">
          <Menu.Header>{t("courseForm.similarCourses")}</Menu.Header>
          {sortedTemplates.map((sortedTemplate: any) => {
            if (!sortedTemplate.similarCourse) {
              return false;
            }
            const item = (
              <MenuItem key={index} option={sortedTemplate} position={index}>
                <Highlighter search={state.text}>
                  {sortedTemplate.name}
                </Highlighter>
                <div>
                  <small>({sortedTemplate.courseShortTitle})</small>
                </div>
              </MenuItem>
            );
            indexSimilar += 1;
            index += 1;
            return item;
          })}
        </Fragment>
        <Fragment key="otherCourses">
          <Menu.Divider />
          <Menu.Header>{t("courseForm.otherCourses")}</Menu.Header>
          {sortedTemplates.map((sortedTemplate: any) => {
            if (sortedTemplate.similarCourse) {
              return false;
            }
            const item = (
              <MenuItem key={index} option={sortedTemplate} position={index}>
                <Highlighter search={state.text}>
                  {sortedTemplate.name}
                </Highlighter>
                <div>
                  <small>({sortedTemplate.courseShortTitle})</small>
                </div>
              </MenuItem>
            );
            indexOther += 1;
            index += 1;
            return item;
          })}
        </Fragment>
      </>
    );
    return <Menu {...menuProps}>{items}</Menu>;
  };

  const fhsysCourseInstructors = currentCourse.fhsysTeachers.map(
    (teacher: any, i: any) => {
      return <div>{teacher.name}</div>;
    }
  );
  const fhsysTeachers = currentCourse.fhsysCoTeachers.map(
    (teacher: any, i: any) => {
      return <div>{teacher.name}</div>;
    }
  );

  const stgInputValues = stgs.map((stg: Stg) => stg.name);

  const semesterOptions = semesters.map((semester: Semester) => (
    <option
      key={semester.wsSs + semester.year}
      value={semester.wsSs + semester.year}
    >
      {semester.wsSs} {semester.year}
    </option>
  ));
  const [selectedTeacher, setSelectedTeacher] = useState<any[]>([]);
  const [selectedCoTeachers, setSelectedCoTeachers] = useState<any[]>([]);
  const [selectedNonEditingTeachers, setSelectedNonEditingTeachers] = useState<
    any[]
  >([]);
  const [selectedOldCourseTemplate, setSelectedOldCourseTemplate] = useState<
    any[]
  >([]);
  const [selectedStg, setSelectedStg] = useState<any[]>([]);
  const [selectedMoodleLanguage, setSelectedMoodleLanguage] =
    useState<string>("german");
  const [advancedModeActive, setAdvancedModeActive] = useState<boolean>(false);
  const [studentsSyncActive, setStudentsSyncActive] = useState<boolean>(true);

  const [teacherComment, setTeacherComment] = useState<string>("");
  const [hiddenTeacherComment, setHiddenTeacherComment] = useState<string>("");
  const [courseTemplateNotFoundInput, setCourseTemplateNotFoundInput] = useState<string>("");

  const [courseDescription, setCourseDescription] = useState<string>(
    currentCourse.descriptionGerman
  );
  const [oldCourseTemplates, setOldCourseTemplates] = useState<
    CourseTemplate[]
  >([]);

  const [showStudentSyncModal, setShowStudentSyncModal] =
    useState<boolean>(false);
  const [showManualRequestModal, setShowManualRequestModal] =
    useState<boolean>(false);

  const [wizardStep, setWizardStep] = useState<number>(0);

  const nextWizardStep = () => {
    if (wizardStep < 4) {
      setWizardStep(wizardStep + 1);
    }
  };
  const previousWizardStep = () => {
    if (wizardStep > 0) {
      setWizardStep(wizardStep - 1);
    }
  };

  const getClassNameForWizardStep = (step: number) => {
    var className = "wizardStep";
    if (step === wizardStep) {
      className += " active";
    }
    return className;
  };

  const handleCancel = () => {
    handleClose();
    setSelectedCoTeachers([]);
    setSelectedNonEditingTeachers([]);
    setSelectedStg([]);
    setSelectedMoodleLanguage("german");
    setAdvancedModeActive(false);
    setStudentsSyncActive(true);
    setSelectedOldCourseTemplate([]);
    setWizardStep(0);
  };
  const handleCreate = async (event: any) => {
    const newCourse = { ...currentCourse };
    event.preventDefault();
    newCourse.teachers = [];
    newCourse.coTeachers = selectedCoTeachers;
    newCourse.nonEditingTeachers = selectedNonEditingTeachers;
    newCourse.moodleLanguage = selectedMoodleLanguage;
    newCourse.studentsSyncActive = studentsSyncActive;
    if (hiddenTeacherComment) {
      newCourse.teacherComment = hiddenTeacherComment + "\n" + teacherComment;
    } else {
      newCourse.teacherComment = teacherComment;
    }
    
    newCourse.descriptionGerman = courseDescription;
    newCourse.advancedModeActive = advancedModeActive;
    if (selectedOldCourseTemplate.length > 0) {
      newCourse.templateOldCourseId = selectedOldCourseTemplate[0].id;
    }
    if (!advancedModeActive) {
      newCourse.studentsSyncActive = true;
    }
    if (!advancedModeActive && !hiddenTeacherComment) {
      newCourse.teacherComment = "";
    }
    if (advancedModeActive && !teacherComment) {
      setErrorMessage(t("courseForm.reasonRequiredMessage"));
    } else {
      if (hiddenTeacherComment) {
        newCourse.advancedModeActive = true;
      }
      await handleSubmit(newCourse);
      setSelectedCoTeachers([]);
      setSelectedNonEditingTeachers([]);
      setSelectedStg([]);
      setSelectedMoodleLanguage("german");
      setAdvancedModeActive(false);
      setStudentsSyncActive(true);
      setSelectedOldCourseTemplate([]);
      setWizardStep(0);
    }
  };

  const getOldCourseTemplates = async () => {
    await handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
      selectedCoTeachers
    );
  };
  const handleInitialGetRemoteOldCourseTemplatesForCurrentUser = async (
    courseCoTeachers: any
  ) => {
    const teachers = [];
    for (const teacher of courseCoTeachers) {
      teachers.push(teacher.id);
    }
    for (const teacher of currentCourse.fhsysTeachers) {
      teachers.push(teacher.id);
    }
    for (const teacher of currentCourse.fhsysCoTeachers) {
      teachers.push(teacher.id);
    }
    const data = await getRemoteOldCourseTemplatesForCurrentUser(teachers);
    setOldCourseTemplates(data);
  };

  useEffect(() => {
    getOldCourseTemplates();
  }, []);

  const handleStudentSyncModalClose = () => {
    setShowStudentSyncModal(false);
  };
  const handleStudentSyncModalShow = () => {
    setShowStudentSyncModal(true);
  };
  const handleManualRequestModalClose = () => {
    setShowManualRequestModal(false);
  };
  const handleManualRequestModalShow = () => {
    setShowManualRequestModal(true);
  };

  const oldCoursesPrefix = t('courseForm.oldCoursesPrefix');
  const oldCourseTemplateInputValues = oldCourseTemplates.map(
    (courseTemplate: CourseTemplate) => {
      let courseTitle = courseTemplate.courseTitle;
      if (courseTemplate.source == 'oldMoodle') {
        courseTitle = oldCoursesPrefix + " " + courseTemplate.courseTitle;
      }
      return {
        id: courseTemplate.courseId,
        name: courseTitle,
        courseShortTitle: courseTemplate.courseShortTitle,
        source: courseTemplate.source
      };
    }
  );

  const CirricularTitle = () => {
    return (
      <>
        <Form.Group className="mb-3" controlId="courseForm.title">
          <Form.Label>{t("courseForm.courseTitle")}</Form.Label>
          {selectedMoodleLanguage == "german" ? (
            <Form.Control defaultValue={currentCourse.titleGerman} disabled />
          ) : (
            <Form.Control defaultValue={currentCourse.titleEnglish} disabled />
          )}
        </Form.Group>
      </>
    );
  };

  const infoAdvancedMode = (
    <Popover id="popover-basic">
      <Popover.Header className="d-flex justify-content-between align-items-center">
        <div className="h5" style={{ marginTop: "0.1rem", marginBottom: "0" }}>
          Info
        </div>
      </Popover.Header>
      <Popover.Body>{t("courseForm.advancedModeInfoText")}</Popover.Body>
    </Popover>
  );

  const deactivateStudentSyncButtonVariant = studentsSyncActive
    ? "outline-danger"
    : "outline-secondary";

  return (
    <Modal backdrop='static' show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t("courseForm.requestCourse")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleCreate}>
        <Modal.Body>
          <div className="mb-3">
            {t("singleCourseText")}:{" "}
            <strong>
              {currentCourse.titleGerman} ({currentCourse.id})
            </strong>
          </div>
          <div className={getClassNameForWizardStep(0)}>
            <Form.Group className="mb-3" controlId="courseForm.moodleLanguage">
              <Form.Label>{t("courseForm.languageLabel")}</Form.Label>
              <Form.Select
                aria-label={t("courseForm.chooseLanguage")}
                value={selectedMoodleLanguage}
                onChange={(a) => {
                  setSelectedMoodleLanguage(a.target.value);
                  if (a.target.value == "english") {
                    setCourseDescription(currentCourse.descriptionEnglish);
                    console.log(currentCourse.descriptionEnglish);
                  } else {
                    setCourseDescription(currentCourse.descriptionGerman);
                    console.log(currentCourse.descriptionGerman);
                  }
                }}
              >
                <option value="german">{t("german")}</option>
                <option value="english">{t("english")}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="courseForm.LVANumber">
              <Form.Label>{t("courseForm.lvaNumberLabel")}</Form.Label>
              <Form.Control
                autoFocus
                defaultValue={currentCourse.id}
                disabled
              />
            </Form.Group>

            <CirricularTitle />

            <Form.Group className="mb-3" controlId="courseForm.department">
              <Form.Label>{t("courseForm.departmentLabel")}</Form.Label>
              <Form.Control
                autoFocus
                defaultValue={currentCourse.departmentGerman}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="courseForm.fachbereich">
              <Form.Label>{t("courseForm.fachbereichLabel")}</Form.Label>
              <Form.Control
                defaultValue={currentCourse.fachbereichGerman}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="courseForm.semester">
              <Form.Label>{t("courseForm.semesterLabel")}</Form.Label>
              <Form.Control
                defaultValue={`${currentCourse.wsSs} ${currentCourse.year}`}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="courseForm.stg">
              <Form.Label>{t("courseForm.stgLabel")}</Form.Label>
              <Form.Control defaultValue={currentCourse.stg} disabled />
            </Form.Group>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={() => {
                  previousWizardStep();
                }}
              >
                {t("courseForm.wizzardPrevious")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  nextWizardStep();
                }}
                className="ms-2"
              >
                {t("courseForm.wizzardNext")}
              </Button>
            </div>
          </div>
          <div className={getClassNameForWizardStep(1)}>
            <Form.Group>
              <Form.Label>
                {t("courseForm.fhsysCourseInstructorLabel")}
              </Form.Label>
              {fhsysCourseInstructors}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>{t("courseForm.fhsysTeacherLabel")}</Form.Label>
              {fhsysTeachers}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>{t("courseForm.moreTeachers")}</Form.Label>
              <Typeahead
                id="courseForm.coTeacher"
                labelKey="name"
                multiple
                onChange={(selected) => {
                  setSelectedCoTeachers(selected);
                  handleInitialGetRemoteOldCourseTemplatesForCurrentUser(
                    selected
                  );
                }}
                options={teachersInputValues}
                placeholder={t("courseForm.pleaseChoose")}
                selected={selectedCoTeachers}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>{t("courseForm.moreNonEditingTeachers")}</Form.Label>
              <Typeahead
                id="courseForm.nonEditingTeacher"
                labelKey="name"
                multiple
                onChange={setSelectedNonEditingTeachers}
                options={teachersInputValues}
                placeholder={t("courseForm.pleaseChoose")}
                selected={selectedNonEditingTeachers}
              />
            </Form.Group>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={() => {
                  previousWizardStep();
                }}
              >
                {t("courseForm.wizzardPrevious")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  nextWizardStep();
                }}
                className="ms-2"
              >
                {t("courseForm.wizzardNext")}
              </Button>
            </div>
          </div>
          <div className={getClassNameForWizardStep(2)}>
            <strong>{t("courseForm.chooseTemplateLabel")}</strong>
            <p>{t("courseForm.chooseTemplateInfoText1")}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("courseForm.chooseTemplateInfoText2", {
                  linkStart: "<a href='' target='_blank'>",
                  linkEnd: "</a>",
                }),
              }}
            ></p>
            <Form.Group className="mt-3">
              <Form.Label>{t("courseForm.courseTemplateLabel")}</Form.Label>
              <Typeahead
                id="courseForm.courseTemplate"
                labelKey="name"
                onChange={setSelectedOldCourseTemplate}
                renderMenu={oldCourseTemplateMenuItems}
                options={oldCourseTemplateInputValues}
                placeholder={t("courseForm.chooseTemplate")}
                selected={selectedOldCourseTemplate}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>{t("courseForm.courseTemplateNotFoundLabel")}</Form.Label>
              <Form.Control
                    type="text"
                    name="courseTemplateNotFound"
                    value={courseTemplateNotFoundInput}
                    onChange={(event) => {
                      setCourseTemplateNotFoundInput(event.target.value);
                      if (event.target.value) {
                        setHiddenTeacherComment("Kurstemplate wurde nicht gefunden: " + event.target.value);
                      } else {
                        setHiddenTeacherComment("");
                      }
                    }}
                  />
            </Form.Group>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={() => {
                  previousWizardStep();
                }}
              >
                {t("courseForm.wizzardPrevious")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  nextWizardStep();
                }}
                className="ms-2"
              >
                {t("courseForm.wizzardNext")}
              </Button>
            </div>
          </div>
          <div className={getClassNameForWizardStep(3)}>
            <Form.Group className="mb-3" controlId="courseForm.description">
              <Form.Label>{t("courseForm.courseDescription")}</Form.Label>
              <p>{t("courseForm.courseDescriptionInfo")}</p>
              <Form.Control
                as="textarea"
                rows={5}
                defaultValue={courseDescription}
                onChange={(e) => setCourseDescription(e.target.value)}
                value={courseDescription}
              />
            </Form.Group>
            <Form.Label>
              {t("courseForm.advancedMode")} &nbsp;
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={infoAdvancedMode}
              >
                <a href="" onClick={(e) => e.preventDefault()}>
                  <FiInfo />
                </a>
              </OverlayTrigger>
            </Form.Label>
            <Form.Check
              type="checkbox"
              id="courseForm.advancedMode"
              label={t("courseForm.advancedModeCheckLabel")}
              defaultChecked={advancedModeActive}
              onChange={(e) => setAdvancedModeActive(e.target.checked)}
            />
            {advancedModeActive && (
              <>
                <Form.Group className="mb-3">
                  <Modal
                    show={showStudentSyncModal}
                    onHide={handleStudentSyncModalClose}
                    backdropClassName="studentSyncBackdrop"
                    className="studentSyncModal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t("caution")}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <p>{t("courseForm.studentSyncInfoText1")}</p>
                      <p>{t("courseForm.studentSyncInfoText2")}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleStudentSyncModalClose();
                        }}
                      >
                        {t("close")}
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setStudentsSyncActive(false);
                          handleStudentSyncModalClose();
                        }}
                      >
                        {t("courseForm.studentSyncDeactivate")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Form.Group className="mb-3">
                    <Form.Text id="textForManualWork" muted>
                      {t("courseForm.noStudentSyncInfo")}
                    </Form.Text>
                  </Form.Group>
                  {studentsSyncActive && (
                    <Form.Label id="textForManualWork" muted>
                      {t("courseForm.studentSyncStatusActivated")}{" "}
                    </Form.Label>
                  )}
                  {!studentsSyncActive && (
                    <Form.Label id="textForManualWork" muted>
                      {t("courseForm.studentSyncStatusDeactivated")}{" "}
                      <a
                        href="#"
                        onClick={() => {
                          setStudentsSyncActive(true);
                        }}
                      >
                        {t("cancel")}
                      </a>
                    </Form.Label>
                  )}
                  <div>
                    <Button
                      variant={deactivateStudentSyncButtonVariant}
                      size="sm"
                      className="mb-1"
                      onClick={() => {
                        handleStudentSyncModalShow();
                      }}
                      disabled={!studentsSyncActive}
                    >
                      {t("courseForm.studentSyncDeactivate")}
                    </Button>
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="courseForm.teacherComment"
                >
                  <Form.Label>{t("courseForm.reasonLabel")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    defaultValue={currentCourse.teacherComment}
                    onChange={(e) => setTeacherComment(e.target.value)}
                    disabled={!advancedModeActive}
                  />
                </Form.Group>
              </>
            )}
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={() => {
                  previousWizardStep();
                }}
              >
                {t("courseForm.wizzardPrevious")}
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={submitLoading}
                className="ms-2"
              >
                {submitLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("courseForm.createButton")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
