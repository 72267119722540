import {
  Button,
  Table,
  Spinner,
  ToastContainer,
  Toast,
  Modal,
  Popover,
  CloseButton,
  OverlayTrigger,
  Badge,
} from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { Course } from "../../entities/course";
import CourseForm from "./CourseForm";
import CourseListItem from "./CourseListItem";
import { Teacher } from "../../entities/teacher";
import { Semester } from "../../entities/semester";
import { Stg } from "../../entities/stg";
import {
  createRemoteCourse,
  createRemoteTeams,
  getRemoteCoursesForCurrentUser,
  getRemoteOldCourseTemplatesForCurrentUser,
  getRemoteSemesters,
  getRemoteStgs,
  getRemoteTeachers,
} from "../../services/api";
import { UserContext } from "../../userContext";
import CourseFilter from "./CourseFilter";
import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";
import { useInterval } from "usehooks-ts";

export default function Courses() {
  const { t, i18n } = useTranslation("common");
  const user = useContext(UserContext);

  const [show, setShow] = useState(false);
  const [currentCourse, setCurrentCourse] = useState<Course>();
  const [allCourses, setAllCourses] = useState<Course[]>([]);
  //const [courses, setCourses] = useState<Course[]>([]);
  const [courseRequestLoading, setCourseRequestLoading] =
    useState<boolean>(true);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [stgs, setStgs] = useState<Stg[]>([]);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showTeamsLoading, setShowTeamsLoading] = useState<boolean>(false);
  const [showLongLoading, setShowLongLoading] = useState<boolean>(false);

  const [showImportInfo, setShowImportInfo] = useState<boolean>(false);

  const emptyFilters = { teachers: "", title: "" };
  const [filterValues, setFilterValues] = useState<any>(emptyFilters);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useInterval(
    () => {
      // Your custom logic here
      setShowLongLoading(true);
    },
    // Delay in milliseconds or null to stop it
    courseRequestLoading ? 2000 : null
  );

  const getCourses = async () => {
    setCourseRequestLoading(true);
    const data = await getRemoteCoursesForCurrentUser();
    if (data.length) {
      setAllCourses(data);
    }
    setCourseRequestLoading(false);
    setShowLongLoading(false);
  };
  const getTeachers = async () => {
    const data = await getRemoteTeachers();
    setTeachers(data);
  };
  const getSemesters = async () => {
    const data = await getRemoteSemesters();
    setSemesters(data);
  };
  const getStgs = async () => {
    const data = await getRemoteStgs();
    setStgs(data);
  };

  const titleRegex = new RegExp(filterValues.title.toLowerCase());
  const courses = allCourses.filter((course) => {
    let ret = true;
    if (filterValues.title) {
      if (course.titleGerman.toLowerCase().search(titleRegex) != -1) {
        ret = true;
      } else {
        if (course.id.toLowerCase().search(titleRegex) != -1) {
          ret = true;
        } else {
          ret = false;
        }
      }
    }
    if (filterValues.teacher) {
      let teacherFound = false;
      course.fhsysTeachers.forEach((fhsysTeacher: any) => {
        if (fhsysTeacher.id == filterValues.teacher) {
          teacherFound = true;
        }
      });
      course.fhsysCoTeachers.forEach((fhsysTeacher: any) => {
        if (fhsysTeacher.id == filterValues.teacher) {
          teacherFound = true;
        }
      });
      if (!teacherFound) {
        ret = false;
      }
    }
    return ret;
  });

  const createCourses = async (course: Course) => {
    const formData = new FormData();
    Object.keys(course).forEach((key: string) => {
      const param = course[key as keyof Course];
      if (
        key == "teacher" ||
        key == "coTeachers" ||
        key == "nonEditingTeachers"
      ) {
        formData.append(key, JSON.stringify(param));
      } else if (param) {
        formData.append(key, param.toString());
      } else {
        formData.append(key, "");
      }
    });
    createRemoteCourse(formData);
  };

  const handleCreateTeam = async (course: any) => {
    const formData = new FormData();
    formData.append("courseTitle", course.titleGerman); //TODO translate
    formData.append("shortSemester", course.wsSs + course.year.substring(2));
    formData.append("shortStg", course.stg);
    if (course.id) {
      formData.append("fhsysCourseId", course.id);
    }
    formData.append("teachers", "[]");
    setShowTeamsLoading(true);
    await createRemoteTeams(formData);
    setShowTeamsLoading(false);
    setAllCourses([]);
    getCourses();
  };

  const handleSubmit = async (newCourse: Course) => {
    setSubmitLoading(true);
    await createCourses(newCourse);
    setAllCourses([]);
    setSubmitLoading(false);
    getCourses();
    handleClose();
    if (newCourse.templateOldCourseId) {
      setShowImportInfo(true);
    }
  };

  const handleReloadCourses = () => {
    setAllCourses([]);
    getCourses();
  };

  useEffect(() => {
    getCourses();
    getTeachers();
    getSemesters();
    getStgs();
  }, []);

  const listItems = courses.map((course) => (
    <CourseListItem
      key={course.id}
      course={course}
      setCurrentCourse={setCurrentCourse}
      handleShow={handleShow}
      handleCreateTeam={handleCreateTeam}
      handleReloadCourses={handleReloadCourses}
    />
  ));
  let courseTeachers: any = {};
  allCourses.forEach((course) => {
    if (course.fhsysTeachers) {
      course.fhsysTeachers.forEach((fhsysTeacher: any) => {
        courseTeachers[fhsysTeacher.id] = fhsysTeacher;
      });
      course.fhsysCoTeachers.forEach((fhsysTeacher: any) => {
        courseTeachers[fhsysTeacher.id] = fhsysTeacher;
      });
    }
  });
  const showInstructor = user?.departments.length || user?.fachbereiche.length;

  const teacherInfoBox = (
    <Popover id="popover-basic">
      <Popover.Header className="d-flex justify-content-between align-items-center">
        <div className="h5" style={{ marginTop: "0.1rem", marginBottom: "0" }}>
          Info
        </div>
      </Popover.Header>
      <Popover.Body>{t("courses.teacherHeaderInfo")}</Popover.Body>
    </Popover>
  );

  const statusInfoBox = (
    <Popover id="popover-basic">
      <Popover.Header className="d-flex justify-content-between align-items-center">
        <div className="h5" style={{ marginTop: "0.1rem", marginBottom: "0" }}>
          Info
        </div>
      </Popover.Header>
      <Popover.Body>
        <div className="mb-2">
          <Badge bg="warning">{t("moodleCourseStatus.inProgress")}</Badge>{" "}
          <br />
          {t("moodleCourseStatus.inProgressInfo")}
        </div>
        <div className="mb-2">
          <Badge bg="info">{t("moodleCourseStatus.importInProgress")}</Badge>{" "}
          <br />
          {t("moodleCourseStatus.importInProgressInfo")}
        </div>
        <div>
          <Badge bg="success">{t("moodleCourseStatus.created")}</Badge> <br />
          {t("moodleCourseStatus.createdInfo")}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      {showTeamsLoading && (
        <div
          className="fullMask"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "black",
            opacity: "0.7",
            zIndex: "1",
          }}
        ></div>
      )}
      <h2>{t("menu.curriclarTitle")}</h2>
      <p style={{ maxWidth: "800px" }}>{t("courses.curricularDescription")}</p>
      <CourseFilter
        showInstructor={showInstructor}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        teachers={courseTeachers}
      />
      <Table className="table">
        <thead>
          <tr>
            <th scope="col">{t("courses.departmentHeader")}</th>
            <th scope="col">{t("courses.stgHeader")}</th>
            <th scope="col">{t("courses.fachbereichHeader")}</th>
            <th scope="col">{t("courses.semesterHeader")}</th>
            {!!showInstructor && (
              <th scope="col">
                {t("courses.teacherHeader")} &nbsp;
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="right"
                  overlay={teacherInfoBox}
                >
                  <a href="" onClick={(e) => e.preventDefault()}>
                    <FiInfo />
                  </a>
                </OverlayTrigger>
              </th>
            )}
            <th scope="col">{t("courses.lvNumberHeader")}</th>
            <th scope="col">{t("courses.titleHeader")}</th>
            <th scope="col">{t("courses.typeHeader")}</th>
            <th scope="col">
              {t("courses.moodleHeader")} &nbsp;
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={statusInfoBox}
              >
                <a href="" onClick={(e) => e.preventDefault()}>
                  <FiInfo />
                </a>
              </OverlayTrigger>
            </th>
            <th scope="col">{t("courses.teamsHeader")}</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </Table>

      {courseRequestLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showLongLoading && (
            <div style={{ marginRight: "2rem" }}>
              {t("courses.longLoadingCourses")}
            </div>
          )}
          <div>
            <Spinner animation="border" role="status" />
          </div>
        </div>
      )}
      {!courseRequestLoading && courses.length <= 0 && (
        <div>{t("courses.noCoursesFound")}</div>
      )}
      {currentCourse && currentCourse.curricular && (
        <CourseForm
          key={currentCourse.id}
          currentCourse={currentCourse}
          show={show}
          handleClose={handleClose}
          submitLoading={submitLoading}
          handleSubmit={handleSubmit}
          teachers={teachers}
          semesters={semesters}
          stgs={stgs}
        />
      )}
      <Modal
        show={showTeamsLoading}
        onHide={() => {
          setShowTeamsLoading(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Teams</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{t("courses.teamsCourseCreationInProgress")}</div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showImportInfo}
        onHide={() => {
          setShowImportInfo(false);
        }}
      >
        <Modal.Header closeButton>{t("courses.templateImport")}</Modal.Header>
        <Modal.Body>{t("courses.templateImportInProgressInfo")}</Modal.Body>
      </Modal>
    </div>
  );
}
