import { Link } from "react-router-dom";
import {
  FiFilePlus,
  FiLayers,
  FiMessageSquare,
  FiRefreshCw,
} from "react-icons/fi";

import "./Menu.css";
import { useContext } from "react";
import { UserContext } from "../userContext";
import { useTranslation } from "react-i18next";

export default function TeamsMenu() {
  const user = useContext(UserContext);
  const { t, i18n } = useTranslation("common");

  if (!user) return <></>;

  return (
    <div className="position-sticky pt-3">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link to="/teams" className="nav-link">
            <FiMessageSquare />
            {"Team beantragen"}
          </Link>
        </li>
        {user.teamsRole.search("admin") !== -1 && (
          <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>{t("menu.adminHeadline")}</span>
            </h6>
            <li className="nav-item">
              <Link to="/teams-admin" className="nav-link">
                <FiLayers /> {"Teams Admin"}
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
