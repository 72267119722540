import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ErrorState {
  errorMessage: string;
  setErrorMessage: (by: string) => void;
}

export const useErrorStore = create<ErrorState>()(
  devtools(
    persist(
      (set) => ({
        errorMessage: "",
        setErrorMessage: (by) =>
          set((state) => ({ errorMessage: by })),
      }),
      {
        name: "error-storage",
      }
    )
  )
);
