import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./TopNavbar.css";

import logo from "./logo-fh.svg";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../userContext";
import { changeRemoteUser, getRemoteTeachers } from "../services/api";
import { Teacher } from "../entities/teacher";
import { Modal, NavDropdown, Toast, ToastContainer } from "react-bootstrap";
import UserChangeForm from "./UserChangeForm";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import QuickLinks from "./Quicklinks";

export const topNavbarVersions = {
  teams: "teams",
  portal: "portal",
  default: "default",
};

export default function TopNavbar(props: any) {
  const { handleUserChange } = props;
  const user = useContext(UserContext);

  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<any>();
  const [showUserChangeModal, setShowUserChangeModal] =
    useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const { t, i18n } = useTranslation("common");

  const getTeachers = async () => {
    const data = await getRemoteTeachers();
    setTeachers(data);
  };
  useEffect(() => {
    getTeachers();
  }, []);

  const handleUserChangeModalShow = () => {
    setShowUserChangeModal(true);
  };
  const handleUserChangeModalHide = () => {
    setShowUserChangeModal(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (selectedTeacher.length) {
      setSubmitLoading(true);
      const formData = new FormData();
      formData.append("teacher", JSON.stringify(selectedTeacher[0]));
      const newUser = await changeRemoteUser(formData);
      handleUserChange(newUser);
      setSubmitLoading(false);
      handleUserChangeModalHide();
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setShowLogoutModal(true);
  };

  if (!user) return <></>;
  return (
    <>
      <header
        className={
          props.version == topNavbarVersions.teams ? "teams" : "default"
        }
      >
        <Navbar expand="md" variant="dark" className="shadow p-0">
          <Navbar.Brand
            href="/"
            className="me-0 px-3"
            style={{
              paddingTop: "0.75rem",
              paddingBottom: "0.75rem",
              fontSize: "1.2rem",
            }}
          >
            <img src={logo} height="40px" width="40px" className="pe-1" />
            {props.version == topNavbarVersions.portal && (
              <span className="topNavbarTitle">Portal</span>
            )}
            {props.version == topNavbarVersions.teams && (
              <span className="topNavbarTitle">Teams Bestelltool</span>
            )}
            {props.version == topNavbarVersions.default && (
              <span className="topNavbarTitle">{t("topNavbar.title")}</span>
            )}
          </Navbar.Brand>
          <NavDropdown
            id="nav-dropdown"
            title={i18n.resolvedLanguage?.toUpperCase()}
            menuVariant="dark"
          >
            <NavDropdown.Item
              href="#"
              onClick={() => i18n.changeLanguage("de")}
            >
              Deutsch (de)
            </NavDropdown.Item>
            <NavDropdown.Item
              href="#"
              onClick={() => i18n.changeLanguage("en")}
            >
              English (en)
            </NavDropdown.Item>
          </NavDropdown>
          <div className="justify-content-end px-3 d-flex flex-grow-1">
            <QuickLinks />
            <NavDropdown
              id="nav-dropdown"
              title={`${user.firstname} ${user.lastname}`}
              menuVariant="dark"
            >
              {(user.role == "admin" || user.originalRole == "admin") && (
                <NavDropdown.Item
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    handleUserChangeModalShow();
                  }}
                >
                  {t("changeUser")}
                </NavDropdown.Item>
              )}

              <NavDropdown.Item href="#" onClick={handleLogout}>
                {t("logout.buttonText")}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Navbar>
      </header>
      <Modal show={showUserChangeModal} onHide={handleUserChangeModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("changeUser")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserChangeForm
            handleSubmit={handleSubmit}
            teachers={teachers}
            selectedTeacher={selectedTeacher}
            setSelectedTeacher={setSelectedTeacher}
            submitLoading={submitLoading}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showLogoutModal}
        onHide={() => {
          setShowLogoutModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("logout.buttonText")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{t("logout.successMessage")}</div>
        </Modal.Body>
      </Modal>
    </>
  );
}
