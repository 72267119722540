import { Table, Spinner, Badge } from "react-bootstrap";
import { useEffect, useState } from "react";

import {
  createRemoteTeams,
  getRemoteCompletedRequestedCourses,
  resolveTemplateSyncError,
} from "../../services/api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import "./CoursesAdmin.css";

export default function CompletedCoursesAdmin() {
  const { t, i18n } = useTranslation("common");
  const [courses, setCourses] = useState<any[]>([]);
  const [showTeamsLoading, setShowTeamsLoading] = useState<boolean>(false);


  const getAllRequestedCourses = async () => {
    const data = await getRemoteCompletedRequestedCourses();
    setCourses(data);
  };

  const handleCreateTeam = async (course: any) => {
    const formData = new FormData();

    formData.append("courseTitle", course.titleMoodle);
    formData.append("shortSemester", course.wsSs + course.year.substring(2));
    if (course.fhsysCourseId) {
      formData.append("fhsysCourseId", course.fhsysCourseId);
    } else {
      formData.append("requestedId", course.id);
    }

    formData.append("teachers", "[]");
    setShowTeamsLoading(true);
    await createRemoteTeams(formData);
    setShowTeamsLoading(false);
    setCourses([]);
    getAllRequestedCourses();
  };


  const handleTemplateSyncErrorResolved = async (course: any) => {
    const formData = new FormData();
    formData.append("courseId", course.id);
    formData.append("fhsysCourseId", course.fhsysCourseId);
    await resolveTemplateSyncError(formData);
    getAllRequestedCourses();
  };

  useEffect(() => {
    getAllRequestedCourses();
  }, []);

  const listItems = courses.map((course) => (
    <tr className={"priority-" + course.priority} key={course.id}>
      <td>
        {" "}
        {course.curricular ? (
          <Badge className="avatarlike" pill bg="info">
            C
          </Badge>
        ) : (
          <Badge className="avatarlike" pill bg="dark">
            M
          </Badge>
        )}
      </td>
      <td>{dayjs(new Date(course.createdAt)).format(t("dateFormat"))}</td>
      <td>{JSON.parse(course.createdFrom).name}</td>
      <td>
        {JSON.parse(course.teachers).map((teacher: any) => {
          return <div key={course.id + "_" + teacher.id}>{teacher.name}</div>;
        })}
      </td>
      <td>{course.curricular ? course.fhsysCourseId : course.lvaNumber}</td>
      <td>
        <Link to={`/courses-admin/${course.id}`}>{course.titleMoodle}</Link>
      </td>
      <td>{course.stg}</td>
      <td>
        {course.wsSs} {course.year}
      </td>
      <td>
        {course.moodleCourseStatus == "requested" && (
          <Badge bg="warning">{t("moodleCourseStatus.inProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "accepted" && (
          <Badge bg="success">{t("moodleCourseStatus.created")}</Badge>
        )}
        {course.moodleCourseStatus == "rejected" && (
          <Badge bg="dark">{t("moodleCourseStatus.rejected")}</Badge>
        )}
        {course.moodleCourseStatus == "waitingForTemplateSync" && (
          <Badge bg="info">{t("moodleCourseStatus.importInProgress")}</Badge>
        )}
        {course.moodleCourseStatus == "templateSyncError" && (
          <>
            <Badge bg="danger">{t("moodleCourseStatus.importError")}</Badge>
            <div className="mt-2">
              <a
                href="#"
                onClick={(event: any) => {
                  event.preventDefault();
                  handleTemplateSyncErrorResolved(course);
                }}
              >
                {t("errorResolved")}
              </a>
            </div>
          </>
        )}
      </td>
      <td>
        {course.teamsCourseStatus == "requested" && (
          <Badge bg="warning">{t("teamsCourseStatus.inProgress")}</Badge>
        )}
        {course.teamsCourseStatus == "accepted" && (
          <Badge bg="success">{t("teamsCourseStatus.created")}</Badge>
        )}
        {course.moodleCourseStatus == "accepted" &&
          course.teamsCourseStatus != "accepted" &&
          course.teamsCourseStatus != "requested" && (
            <a
              href="#"
              className="link-primary"
              onClick={() => {
                handleCreateTeam(course);
              }}
            >
              {t("courseList.requestTeamsCourse")}
            </a>
          )}
      </td>
    </tr>
  ));

  return (
    <div>
      {showTeamsLoading && (
        <div
          className="fullMask"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "black",
            opacity: "0.7",
            zIndex: "1",
          }}
        ></div>
      )}
      <h2>{t("menu.moodleAdminTitle")}</h2>
      <Table className="table">
        <thead>
          <tr>
          <th scope="col"></th>
            <th scope="col">{t("courses.creationHeader")}</th>
            <th scope="col">{t("courses.createrHeader")}</th>
            <th scope="col">{t("courses.teacherHeader")}</th>
            <th scope="col">{t("courseForm.shortNameLabel")}</th>
            <th scope="col">{t("courses.titleHeader")}</th>
            <th scope="col">{t("courses.stgHeader")}</th>
            <th scope="col">{t("courses.semesterHeader")}</th>
            <th scope="col">{t("courses.moodleHeader")}</th>
            <th scope="col">{t("courses.teamsHeader")}</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </Table>
      {courses.length <= 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
